import React, { useState } from "react";
import Notiflix from "notiflix";
import PostApiCall from "../../Helper/PostApi";
import { FaLockOpen } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import { AiFillEyeInvisible } from "react-icons/ai";

export default function ChangePassword({ isOpen, handdleToggleModal }) {
	const [newPassword, SetNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showNewPass, setShowNewPass] = useState(false)
	const [showConfirm, setShowConfirm] = useState(false)
	const history = useHistory();

	const onChangePassword = () => {
		Notiflix.Loading.dots('');
		var login = localStorage.getItem('CustomerData');
		var details = JSON.parse(login)
		PostApiCall.postRequest({
			userid: details[0].fld_customerid,
			password: confirmPassword,
			salt: details[0].fld_salt,
		}, "ChangeCustomerPassword").then((results) =>
			results.json().then(obj => {
				if (results.status == 200 || results.status == 201) {
					Notiflix.Loading.remove()
					handdleToggleModal()
					Notiflix.Notify.success('Password Successfully updated')
					// history.push('/')
				}
				else {
					Notiflix.Loading.remove()
					Notiflix.Notify.failure('something went wrong, try again later')
				}
			}
			)
		)
	}

	const handleChangePassword = () => {
		if (newPassword != "") {
			if (confirmPassword != "") {
				if (newPassword == confirmPassword) {
					onChangePassword()
				} else {
					Notiflix.Notify.failure("New Password and Confirm New Password does not match")
				}
			} else {
				Notiflix.Notify.failure("Confirm New Password can not be empty")
			}
		} else {
			Notiflix.Notify.failure("New Password can not be empty")
		}
	}

	return (
		<>
			{/* <div className="change-pass-icon" onClick={handleShow}>
				<FaLockOpen />
			</div> */}

			<Modal show={isOpen} onHide={handdleToggleModal} centered>
				<Modal.Header closeButton className="pb-0 border-0">
					{/* <Modal.Title>Change Password</Modal.Title> */}
				</Modal.Header>
				<Modal.Body>
					<div className="change-pass-icon mb-4">
						<FaLockOpen />
						<h2>Change Password</h2>
					</div>
					<Form>
						<Form.Group controlId="newPassword" className="mb-3">
							<Form.Control
								type={showNewPass ? "text" : "password"}
								placeholder="New Password"
								value={newPassword}
								onChange={(e) => SetNewPassword(e.target.value)}
							/><AiFillEyeInvisible
								role={"button"}
								onClick={() =>
									setShowNewPass(!showNewPass)
								}
								style={{
									position: "absolute",
									right: "5%",
									bottom: "42%",
								}}
							/>
						</Form.Group>
						<Form.Group controlId="confirmPassword">
							<Form.Control
								type={showConfirm ? "text" : "password"}
								placeholder="Confirm Password"
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
							<AiFillEyeInvisible
								role={"button"}
								onClick={() =>
									setShowConfirm(!showConfirm)
								}
								style={{
									position: "absolute",
									right: "5%",
									bottom: "25%",
								}}
							/>
						</Form.Group>
					</Form>
					<div className="mt-3">
						<button
							className="theme-btn text-center w-100 d-block border-0"
							onClick={handleChangePassword}>
							Change Password
						</button>
					</div>
				</Modal.Body>
				{/* <Modal.Footer>
          <Button variant="secondary" onClick={handdleToggleModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleChangePassword}>
            Change Password
          </Button>
        </Modal.Footer> */}
			</Modal>
		</>
	);
}

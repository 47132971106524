import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap';
import LoginUser from './LoginUser';
import PostApiCall from '../../Helper/PostApi';
import Notiflix from 'notiflix';
import moment from 'moment';
import { store } from '../../Helper/Context/Store';
import { useHistory } from 'react-router-dom';
import RegisterUser from './RegisterUser';
import GuestLogin from './GuestLogin';
import ForgotPassword from './ForgetPassword';

const NewLoginModal = ({ isOpen, handleToggleModal, modalSide = "Log", handleChangeModalSide }) => {


	const { setcart, setcartamount, setCartItems } = useContext(store)



	const onPostMoveCart = (obj) => {
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);

		Notiflix.Loading.circle();

		PostApiCall.postRequest(
			{
				customerid: obj.data[0].fld_customerid,
				cartid: Orderdetails[0].order_id,
			},
			"MoveTmpCrtToMain"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					// localStorage.removeItem("OrderData");
					Notiflix.Loading.remove();
					getCartdetails()
					// if (localStorage.getItem("ReviewPage") == "ReviewPage") {
					// 	history.goBack()
					// } else {
					// 	// (window.location.href = "/");
					// }
				} else {
					Notiflix.Loading.remove();
					Notiflix.Notify.failure(obj.data);
				}
			})
		);
	}

	const addToCart = () => {
		var login = localStorage.getItem("CustomerData");
		var logindetails = JSON.parse(login);
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);
		var itemdetail = JSON.parse(localStorage.getItem("itemdetails"));
		PostApiCall.postRequest(
			{
				orderdate: moment().format("lll"),
				itemdetails: `
                                          [
                                          {
                                              "Item":${itemdetail[0]?.Item},
                                              "Quantity":${1}
                                          }
                                          ]
                                          `,
				status: "INCART",
				customerid:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
				createdon: moment().format("lll"),
				updatedon: moment().format("lll"),
				orderid:
					localStorage.getItem("OrderData") == null
						? 0
						: Orderdetails[0].order_id,
				updatedby:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_userid,
			},
			"AddToCartWebsite"
		).then((result) =>
			result.json().then((obj) => {
				if (result.status == 200 || result.status == 201) {
					Notiflix.Loading.remove();
					Notiflix.Notify.success("Product added to Cart.");
					if (localStorage.getItem("OrderData") == null) {
						localStorage.setItem("OrderData", JSON.stringify(obj.data));
					}
					getCartdetails();
					localStorage.removeItem("itemdetails")
				} else {
					Notiflix.Loading.remove();
					Notiflix.Notify.failure(obj.data);
				}
			})
		);
	}
	const getCartdetails = () => {
		Notiflix.Loading.circle();
		var login = localStorage.getItem("CustomerData");
		var logindetails = JSON.parse(login);
		var Order = localStorage?.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);
		PostApiCall.postRequest(
			{
				orderid:
					localStorage.getItem("OrderData") == null
						? null
						: Orderdetails[0].order_id,
				id:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
			},
			"GetCartWebsite"
		)
			.then((res) => res.json())
			.then((res1) => {
				if (res1.data) {
					Notiflix.Loading.remove();

					setcart(res1?.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
					setCartItems(res1.data)
					setcartamount(res1.data[0]?.fld_cart_amount_preview)
					// setcartamount(res1.data[0]?.fld_cart_amount_preview);
				}
			});
	};
	return (
		<>
			<Modal show={isOpen} centered onHide={() => {
				handleToggleModal();
			}} >
				<Modal.Header closeButton className="border-0 pb-0" />
				<Modal.Body className="px-3 pt-0">
					{modalSide === "Log" && <LoginUser handleToggleModal={handleToggleModal} addToCart={addToCart} getCartdetails={getCartdetails} onPostMoveCart={onPostMoveCart} handleChangeModalSide={handleChangeModalSide} />}
					{modalSide === "Reg" && <RegisterUser handleToggleModal={handleToggleModal} addToCart={addToCart} getCartdetails={getCartdetails} onPostMoveCart={onPostMoveCart} handleChangeModalSide={handleChangeModalSide} />}
					{modalSide === "guest" && <GuestLogin handleToggleModal={handleToggleModal} addToCart={addToCart} getCartdetails={getCartdetails} onPostMoveCart={onPostMoveCart} handleChangeModalSide={handleChangeModalSide} />}
					{modalSide === "forgetPass" && <ForgotPassword handleToggleModal={handleToggleModal} addToCart={addToCart} getCartdetails={getCartdetails} onPostMoveCart={onPostMoveCart} handleChangeModalSide={handleChangeModalSide} />}
				</Modal.Body>
			</Modal>
		</>
	)
}

export default NewLoginModal
import React, { useContext, useState } from 'react'
import { BiSolidUserPlus } from 'react-icons/bi'
import { store } from '../../Helper/Context/Store'
import Notiflix from 'notiflix'
import PostApiCall from '../../Helper/PostApi'

const GuestLogin = ({ handleToggleModal, getCartdetails, addToCart, onPostMoveCart, handleChangeModalSide }) => {

	const { setLoggedIn } = useContext(store)
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [mobileNo, setMobileNo] = useState("")
	const [numRegex, setNumRegex] = useState(/^[0-9]*$/);
	const [emailRegex, setEmailRegex] = useState(
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);


	const validateGuestLogin = () => {
		if (name === "") {
			Notiflix.Notify.failure("Please enter your name")
			return
		}
		if (email === "") {
			Notiflix.Notify.failure("Please enter your email")
			return
		}
		if (mobileNo === "") {
			Notiflix.Notify.failure("Please enter your mobile number")
			return
		}
		onGuestLogin()
	}

	const onGuestLogin = () => {
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);
		Notiflix.Loading.circle();
		PostApiCall.postRequest({
			name: name,
			email: email,
			mobile: mobileNo,
			status: "Website",
			cartid:
				localStorage?.getItem("OrderData") == null
					? null
					: Orderdetails[0].order_id,
			signupguest: "Continue",
		}, "AddGuest").then((result) =>
			result.json().then((obj) => {
				if (result.status == 200 || result.status == 201) {
					clearGuestForm()
					Notiflix.Notify.success("Login Successfull")
					localStorage.setItem("CustomerData", JSON.stringify(obj.data))
					if (localStorage.getItem("itemdetails")) {
						addToCart();
					}
					else {
						getCartdetails();
					}
					Notiflix.Loading.remove()
					setLoggedIn(true)
					handleToggleModal()
				} else {
					Notiflix.Loading.remove();
					Notiflix.Notify.failure(obj.data);
				}
			})
		);
	}

	const clearGuestForm = () => {
		setName("")
		setEmail("")
		setMobileNo("")
	}
	return (
		<>
			<div className="section-title mb-0 pb-3 mt-0">
				<div className="login-modal-icon">
					<BiSolidUserPlus />
				</div>
				<h4 className="text-center">
					Seamless Experience with Guest Login!
				</h4>
			</div>
			<p
				style={{
					textAlign: "center",
					marginTop: "-5px",
					marginBottom: "30px",
				}}>
				Start Shopping Instantly!
			</p>

			<div className="row">
				<div className="col-md-12">
					<input
						type="text"
						placeholder="Name"
						className="form-control"
						value={name}
						onChange={(name) => {
							setName(name.target.value)
						}}></input>
				</div>
				<div className="col-md-12" style={{ marginTop: "10px" }}>
					<input
						type="text"
						placeholder="Email ID"
						className="form-control"
						value={email}
						onChange={(email) => {
							setEmail(email.target.value)
						}}></input>
				</div>
				<div className="col-md-12" style={{ marginTop: "10px" }}>
					<input
						type="text"
						placeholder="Mobile Number"
						className="form-control"
						value={mobileNo}
						onChange={(mobile) => {
							if (
								numRegex.test(mobile.target.value) &&
								mobile.target.value.length <= 10
							) {
								setMobileNo(mobile.target.value)
							}
						}}
					/>
				</div>
				<div className="col-md-12 mb-2">
					<button
						onClick={validateGuestLogin}
						className="theme-btn w-100 d-block my-2 text-center border-0">
						Continue
					</button>
				</div>
				<div
					className="newTowebsite-register"
					style={{ display: "flex", justifyContent: "center" }}>
					<p>Already an user?</p>
					<p
						style={{
							marginLeft: "5px",
							cursor: "pointer",
							color: "#221f1f",
							fontWeight: "bold",
						}}
						onClick={() => {
							handleChangeModalSide("Log");
						}}
					>
						Login
					</p>
				</div>
			</div>
		</>
	)
}

export default GuestLogin
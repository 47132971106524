import React from "react";
import offerone from "../../assets/img/home-banner/Slider/offerone.jpg";
import offeronemobile from "../../assets/img/home-banner/Slider/offeronemobile.jpg";
import offertwo from "../../assets/img/home-banner/Slider/offertwo.jpg";
import offertwomobile from "../../assets/img/home-banner/Slider/offertwomobile.jpg";
import offerthree from "../../assets/img/home-banner/Slider/offerthree.jpg";
import offerthreemobile from "../../assets/img/home-banner/Slider/offerthreemobile.jpg";




import "../MainNavabar/MainNavabar.css";
import "./HomeBanner.css";

import { Skeleton } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
export default function HomeBanner({ banners, loadingState }) {
  return (
    <>
      {loadingState === true ? (
        <div className="contanier-fluid homebannersize homebannersize-skeleton p-0 m-0">
          <Skeleton.Image className="w-100 d-none d-lg-block" />

          <div className="mobile-imgae-space mobile-imgae-space-skeleton position-relative">
            <Skeleton.Image className="img-fluid d-block d-lg-none position-static h-100" />
          </div>
        </div>
      ) : (
        <div className="contanier-fluid homebannersize p-0 m-0">
          <div className="mobile-imgae-space position-relative">
            <Swiper
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              slidesPerView={1}
              modules={[Autoplay, Pagination]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}>
              <SwiperSlide>
                <img
                  src={offerone}
                  className="w-100 d-none d-lg-block"
                  alt="Buy 3 get 1 free Kaawaii Cosmetics"
                />
                <img
                  src={offeronemobile}
                  className="img-fluid d-block d-lg-none position-static"
                  alt="Buy 3 get 1 free Kaawaii Cosmetics"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={offertwo}
                  className="w-100 d-none d-lg-block"
                  alt="Buy 3 get 1 free Kaawaii Cosmetics"
                />
                <img
                  src={offertwomobile}
                  className="img-fluid d-block d-lg-none position-static"
                  alt="Buy 3 get 1 free Kaawaii Cosmetics"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={offerthree}
                  className="w-100 d-none d-lg-block"
                  alt="Buy 3 get 1 free Kaawaii Cosmetics"
                />
                <img
                  src={offerthreemobile}
                  className="img-fluid d-block d-lg-none position-static"
                  alt="Buy 3 get 1 free Kaawaii Cosmetics"
                />
              </SwiperSlide>
            </Swiper>
          </div>
          {/* <img
            src={Homebannerimg}
            className="w-100 d-none d-lg-block"
            alt="Kaawaii Cosmetics"
          /> */}

          {/* <div className="mobile-imgae-space position-relative">
            <img
              src={HomeMobileBanner}
              className="img-fluid d-block d-lg-none position-static"
              alt="Kaawaii Cosmetics"
            />
          </div> */}
          {/* <img src={Homebannerimg} className="w-100" /> */}
        </div>
      )}
    </>
  );
}

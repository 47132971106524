import moment from 'moment';
import Notiflix from 'notiflix';
import React, { useContext, useEffect, useState } from 'react'
import { AiFillEyeInvisible } from 'react-icons/ai'
import { BiSolidUserPlus } from 'react-icons/bi'
import { store } from '../../Helper/Context/Store';
import PostApiCall from '../../Helper/PostApi';

const RegisterUser = ({ handleToggleModal, getCartdetails, addToCart, onPostMoveCart, handleChangeModalSide }) => {


	const { logo, cart, setcart, setcartamount, setLoggedIn, clientData, setCartItems } = useContext(store)

	const [numRegex, setNumRegex] = useState(/^[0-9]*$/);
	const [emailRegex, setEmailRegex] = useState(
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [mobile, setMobile] = useState("");
	const [password, setPassword] = useState("");
	const [gender, setGender] = useState("");
	const [passwordVisible, setPasswordVisible] = useState(false)
	const [genderData, setGenderData] = useState([
		{ label: "Male", value: "Male" },
		{ label: "Female", value: "Female" },
		{ label: "Others", value: "Others" },
	]);
	const [dobDay, setDobDay] = useState(1);
	const [dobMonth, setDobMonth] = useState("January");
	const [dobYear, setDobYear] = useState("2021");
	const [month, setMonth] = useState([]);
	const [day, setDay] = useState([]);
	const [year, setYear] = useState([]);


	useEffect(() => {
		Notiflix.Loading.init({
			svgColor: "#0f3d74",
		});

		var day = [];
		var monthdata = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		var month = [];
		var year = [];

		for (var i = 1; i <= 31; i++) {
			day.push({ label: i, value: i });
		}

		const date2 = new Date();

		for (var i = 0; i < Math.abs(date2.getUTCFullYear() - 1924); i++) {
			year.push({
				label: date2.getUTCFullYear() - i,
				value: date2.getUTCFullYear() - i,
			});
		}

		for (var i = 0; i < 12; i++) {
			month.push({ label: monthdata[i], value: monthdata[i] });
		}
		setMonth(month)
		setDay(day)
		setYear(year)
	}, [])


	const SaveRegistration = () => {
		if (name != "") {
			if (email != "") {
				if (emailRegex.test(email)) {
					if (mobile != "") {
						if (mobile.length == 10) {
							if (password != "") {
								onPostRegistration();
							} else {
								Notiflix.Notify.failure("Please enter password.");
							}
						} else {
							Notiflix.Notify.failure("Please enter valid mobile number.");
						}
					} else {
						Notiflix.Notify.failure("Please enter mobile number.");
					}
				} else {
					Notiflix.Notify.failure("Please enter valid email address.");
				}
			} else {
				Notiflix.Notify.failure("Please enter email address.");
			}
		} else {
			Notiflix.Notify.failure("Please enter your name.");
		}
	}


	const onPostRegistration = () => {
		Notiflix.Loading.circle();

		PostApiCall.postRequest(
			{
				name: name,
				email: email,
				mobile: mobile,
				status: "Website",
				password: password,
				createdon: moment().format("lll"),
				updatedon: moment().format("lll"),
				updatedby: 0,
				dob:
					dobYear +
					"-" +
					dobMonth +
					"-" +
					dobDay,
				gender: gender,
			},
			"AddCustomer"
		).then((result) =>
			result.json().then((obj) => {
				if (result.status == 200 || result.status == 201) {
					// Notiflix.Notify.Success("You are Successfully Registered");

					// window.location.href = "/login";
					PostApiCall.postRequest(
						{
							email: email,
							password: password,
							action: "Login",
							actiondate: moment().format("lll"),
						},
						"AddwebsiteLogin"
					).then((results) =>
						results.json().then((obj) => {
							if (
								(results.status == 200 || results.status == 201) &&
								localStorage.getItem("OrderData")
							) {
								localStorage.setItem("CustomerData", JSON.stringify(obj.data)); // set api user data to localstorage
								localStorage.setItem("access", JSON.stringify(obj.token)); // set the token data to access
								Notiflix.Loading.remove();
								onPostMoveCart(obj);
								// getCartdetails();
								setLoggedIn(true)
								handleToggleModal()
							} else if (results.status == 200 || results.status == 201) {
								Notiflix.Loading.remove();
								localStorage.setItem("CustomerData", JSON.stringify(obj.data));
								localStorage.setItem("access", JSON.stringify(obj.token));
								if (localStorage.getItem("itemdetails")) {
									addToCart();
								}
								setLoggedIn(true)
								handleToggleModal()
								getCartdetails();
							} else {
								Notiflix.Loading.remove();
								// Notiflix.Notify.failure(obj.data);
							}
						})
					);
				} else {
					Notiflix.Loading.remove();
					// Notiflix.Notify.failure(obj.data);
				}
			})
		);
	};
	return (
		<>

			<div className="login-box pb-0">
				<div className="section-title mb-0 pb-3 mt-0">
					<div className="login-modal-icon">
						<BiSolidUserPlus />
					</div>
					<h4 className="text-center">
						New to {clientData[0]?.store}
					</h4>
				</div>
				<p
					style={{
						textAlign: "center",
						marginTop: "-5px",
						marginBottom: "30px",
					}}>
					Shop with us & get attractive deals
				</p>

				<div className="row">
					<div className="col-md-12">
						<input
							type="text"
							placeholder="Name"
							className="form-control"
							value={name}
							onChange={(name) => {
								setName(name.target.value)
							}}></input>
					</div>
					<div className="col-md-12" style={{ marginTop: "10px" }}>
						<input
							type="text"
							placeholder="Email ID"
							className="form-control"
							value={email}
							onChange={(email) => {
								setEmail(email.target.value)
							}}></input>
					</div>
					<div className="col-md-12" style={{ marginTop: "10px" }}>
						<input
							type="text"
							placeholder="Mobile Number"
							className="form-control"
							value={mobile}
							onChange={(mobile) => {
								if (
									numRegex.test(mobile.target.value) &&
									mobile.target.value.length <= 10
								) {
									setMobile(mobile.target.value)
								}
							}}
						/>
					</div>
					<div
						className="col-md-12"
						style={{ marginTop: "10px", position: "relative" }}>
						<input
							placeholder="Password"
							className="form-control"
							type={passwordVisible ? "text" : "password"}
							value={password}
							onChange={(pswd) => {
								setPassword(pswd.target.value)
							}}
						/>
						<AiFillEyeInvisible
							role={"button"}
							onClick={() =>
								setPasswordVisible(!passwordVisible)
							}
							style={{
								position: "absolute",
								right: "5%",
								bottom: "36%",
							}}
						/>
					</div>

					<div className="col-md-12" style={{ marginTop: "10px" }}>
						<select
							className="form-control"
							value={gender}
							onChange={(text) => {
								setGender(text.target.value)
							}}>
							<option value=""> Gender</option>
							{genderData.map((data, i) => (
								<option value={data.value} key={i}>
									{data.label}
								</option>
							))}
						</select>
					</div>

					<div className="col-md-12 d-flex align-items-md-center my-2">
						<input type="checkbox" />&nbsp;
						<label>
							By Registering, you agree to our <a href="/terms-conditions">Terms & Conditions</a>.
						</label>
					</div>
					<div className="col-md-12 mb-2">
						<button
							onClick={SaveRegistration}
							className="theme-btn w-100 d-block my-2 text-center border-0">
							Register
						</button>
					</div>

					<div className="col-12 text-center">
						<div
							className="newTowebsite-register"
							style={{ display: "flex", justifyContent: "center" }}>
							<p>Already have an account?</p>
							<p
								style={{
									marginLeft: "5px",
									cursor: "pointer",
									color: "#221f1f",
									fontWeight: "bold",
								}}
								onClick={() => {
									handleChangeModalSide("Log"); // reg to log
									// setModalFace("Log")
								}}
							>
								Login!
							</p>
						</div>
					</div>
					<p className="text-center lef-rigth-border">OR</p>
					<div className="col-md-12 mb-2">
						<button
							onClick={() => { handleChangeModalSide("guest") }}
							className="theme-btn w-100 d-block my-2 text-center border-0">
							Continue as Guest
						</button>
					</div>

				</div>
			</div>
		</>
	)
}

export default RegisterUser
import { Link } from "react-router-dom";
import special from "../../assets/img/offer/special-offer.gif";
import "../OfferSection/OfferSection.css";
export default function OfferSection() {
  return (
    <>
      <section className="special-offer">
        <Link to={"/ct/combos"}>
          <img src={special} />
        </Link>
      </section>
    </>
  );
}

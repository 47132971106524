import { useEffect } from "react";
import "./InstaSection.css";
import GetSeoData from "../../Helper/GetSeoData";
import reelbanner from "../../assets/img/reel-banner.jpg"
import reelbannermobile from "../../assets/img/reel-mobile-banner.jpg"
import InnerBanner from "../InnerBanner/InnerBanner";
import kaawaii1 from "../../assets/img/insta/kaawaii-1.mp4";
import kaawaii2 from "../../assets/img/insta/kaawaii-2.mp4";
import kaawaii3 from "../../assets/img/insta/kaawaii-3.mp4";
import kaawaii4 from "../../assets/img/insta/kaawaii-4.mp4";
import kaawaii5 from "../../assets/img/insta/kaawaii-5.mp4";
import kaawaii6 from "../../assets/img/insta/kaawai-6.mp4";
import kaawaii7 from "../../assets/img/insta/kaawaii-7.mp4"
import { FaInstagram } from "react-icons/fa";

export default function InstagramGrid() {
    const instagramData = [
        {
            url: "https://www.instagram.com/reel/DC9E7DHilqE/?utm_source=ig_web_copy_link",
            gif: kaawaii1,
            alt: "KaawaiiCosmetics",
        },
        {
            url: "https://www.instagram.com/reel/DDglIANzCbP/?utm_source=ig_web_copy_link",
            gif: kaawaii2,
            alt: "KaawaiiCosmetics",
        },
        {
            url: "https://www.instagram.com/reel/DFhcQ9MoJGU/?utm_source=ig_web_copy_link",
            gif: kaawaii3,
            alt: "KaawaiiCosmetics",
        },
        {
            url: "https://www.instagram.com/reel/C-ZYt-gq5k3/?utm_source=ig_web_copy_link",
            gif: kaawaii4,
            alt: "KaawaiiCosmetics",
        },
        {
            url: "https://www.instagram.com/reel/DE7EDmvyUTV/?utm_source=ig_web_copy_link",
            gif: kaawaii5,
            alt: "KaawaiiCosmetics",

        },
        {
            url: "https://www.instagram.com/reel/DCyJNbUCowg/?utm_source=ig_web_copy_link",
            gif: kaawaii6,
            alt: "KaawaiiCosmetics",
        },
        {
            url: "https://www.instagram.com/reel/DFUh3coxpQt/?utm_source=ig_web_copy_link",
            gif: kaawaii7,
            alt: "KaawaiiCosmetics",
        },

    ];

    return (
        <div className="container-fluid insta-section">
            <img
                src={reelbanner}
                className="w-100 d-none d-lg-block"
                alt="Reel Kaawaii Cosmetics"
            />
            <img
                src={reelbannermobile}
                className="w-100 d-block d-lg-none"
                alt="Reel Kaawaii Cosmetics"
            />
            <div className="container h-100">


                <div className="row mx-1 instagram-row h-100 align-items-center pt-lg-5">
                    {instagramData.map((item, index) => (
                        <div
                            key={index}
                            className="col-12 col-sm-4 col-lg-2  mb-0 pb-0 instagram-embed position-relative px-1 mt-lg-0 mt-5"
                        >
                            <a href={item.url} target="_blank" rel="noopener noreferrer">
                                <video
                                    src={item.gif}
                                    autoPlay
                                    loop
                                    muted
                                    className="w-100 insta-video"
                                />
                                <div className="position-absolute instabox top-0 d-flex align-items-center justify-content-center w-100 mx-1">
                                    <FaInstagram className="insta-color" />
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>


        </div>
    );
}

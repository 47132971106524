import moment from 'moment';
import React, { useContext, useState } from 'react'
import { BiSolidUser } from "react-icons/bi";
import PostApiCall from '../../Helper/PostApi';
import Notiflix from 'notiflix';
import { store } from '../../Helper/Context/Store';
import { AiFillEyeInvisible } from "react-icons/ai";
const LoginUser = ({ handleToggleModal, getCartdetails, addToCart, onPostMoveCart, handleChangeModalSide }) => {
	const { setLoggedIn } = useContext(store)

	const [numRegex, setNumRegex] = useState(/^[0-9]*$/);
	const [emailRegex, setEmailRegex] = useState(
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);

	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [passwordVisible, setPasswordVisible] = useState(false)
	const saveLogin = () => {
		if (email != "") {
			if (
				emailRegex.test(email) ||
				numRegex.test(email)
			) {
				if (password != "") {
					onLogin();
				} else {
					Notiflix.Notify.failure("Please enter your password.");
				}
			} else {
				Notiflix.Notify.failure("Please enter valid email or mobile number.");
			}
		} else {
			Notiflix.Notify.failure("Please enter your email or mobile number.");
		}
	}

	const onLogin = () => {
		Notiflix.Loading.circle();
		PostApiCall.postRequest(
			{
				email: email,
				password: password,
				action: "Login",
				actiondate: moment().format("lll"),
			},
			"AddwebsiteLogin"
		).then((results) =>
			results.json().then((obj) => {
				if (
					(results.status == 200 || results.status == 201) &&
					localStorage.getItem("OrderData")
				) {
					localStorage.setItem("CustomerData", JSON.stringify(obj.data)); // set api user data to localstorage
					localStorage.setItem("access", JSON.stringify(obj.token)); // set the token data to access
					Notiflix.Loading.remove();
					onPostMoveCart(obj);
					handleToggleModal()
					// getCartdetails();
					Notiflix.Notify.success("Login Successfull")
					setLoggedIn(true)

				} else if (results.status == 200 || results.status == 201) {
					Notiflix.Loading.remove();
					localStorage.setItem("CustomerData", JSON.stringify(obj.data));
					localStorage.setItem("access", JSON.stringify(obj.token));
					if (localStorage.getItem("itemdetails")) {
						addToCart();
					}
					setLoggedIn(true)
					handleToggleModal()
					getCartdetails();
				} else {
					Notiflix.Loading.remove();
					Notiflix.Notify.failure(obj.data, {
						position: 'center-bottom',
					});
				}
			})
		);
	};
	return (
		<>
			<div className="login-box pb-0">
				<div className="login-modal-icon">
					<BiSolidUser />
				</div>
				<div className="section-title mb-0 pb-3 mt-0">
					<h4 className="text-center">
						Get Fabulous Deals!
					</h4>
				</div>
				<p style={{ textAlign: "center", marginTop: "-10px" }}>
					Login to Order Online
				</p>

				<div style={{ marginTop: "20px" }}>
					<input
						type="text"
						placeholder="Use your Email ID or Mobile No. to Sign in"
						className="form-control"
						value={email}
						onChange={(email) => {
							setEmail(email.target.value)
						}}></input>
				</div>
				<div style={{ marginTop: "10px", position: "relative" }}>
					<input
						type={passwordVisible ? "text" : "password"}
						placeholder="Password"
						className="form-control"
						value={password}
						onChange={(password) => {
							setPassword(password.target.value)
						}}></input>
					<AiFillEyeInvisible
						role={"button"}
						onClick={() =>
							setPasswordVisible(!passwordVisible)
						}
						style={{
							position: "absolute",
							right: "5%",
							bottom: "36%",
						}}
					/>
				</div>

				<div className=" forgot-password mt-2 d-flex justify-content-end align-items-center">
					<a
						// href="/forgotpassword"
						className=" float-right forgot-pass small"
						data-bs-toggle="modal"
						data-bs-target="#exampleModal"
						style={{ cursor: "pointer" }}
						onClick={() => {
							// setOpenForgotPass(true)
							handleChangeModalSide("forgetPass")
						}}
					>
						Forgot Password?
					</a>
				</div>
				<div className="text-center cursor-pointer">
					<button
						className="theme-btn w-100 d-block my-2 border-0"
						onClick={saveLogin}>
						Login
					</button>
				</div>
				<div className="text-center" style={{ marginTop: "10px" }}>
					{/* <img
												src={borderimg}
												alt="borderimg"
											/> */}
					<div
						className="newTowebsite-register"
						style={{ display: "flex", justifyContent: "center" }}>
						<p>New User?</p>
						<p
							style={{
								marginLeft: "5px",
								cursor: "pointer",
								color: "#221f1f",
								fontWeight: "bold",
							}}
							onClick={() => {
								handleChangeModalSide("Reg")
							}}
						>
							Register Now!
						</p>
					</div>
				</div>
				<p className="text-center lef-rigth-border">OR</p>
				<div className="col-md-12 mb-2">
					<button
						onClick={() => {
							handleChangeModalSide("guest")
						}}
						className="theme-btn w-100 d-block my-2 text-center border-0">
						Continue as Guest
					</button>
				</div>
			</div>
		</>
	)
}

export default LoginUser
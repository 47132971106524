import React from "react";
import { RiTreasureMapFill } from "react-icons/ri";
import { Redirect, Route } from "react-router-dom"

function ProtectedRoutes({ component: Component, ...rest }) {


	return (<Route {...rest} render={(props) => {
		const isAuth = localStorage.getItem("CustomerData") == null
			? false
			: true
		if (isAuth == true) {
			return <Component />
		} else {
			return <Redirect to={{ pathname: "/", state: { from: props.location } }} />
		}
	}} />
	)

}

export default ProtectedRoutes;
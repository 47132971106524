import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Combo from "../../assets/img/combo.png";
import "./ModalAward.css";
import { Link } from "react-router-dom";
export default function ModalAward() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer); // Cleanup function to avoid memory leaks
  }, []);

  const handleClose = () => setShow(false);

  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button> */}

      <Modal show={show} onHide={handleClose} centered className="award-modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Link to={"/ct/perfume"}>
            <img src={Combo} className="img-fluid" alt="combo popup" />
          </Link>
        </Modal.Body>
      </Modal>
    </>
  );
}

import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Nav, NavDropdown, Offcanvas } from "react-bootstrap";
import { AiFillInstagram } from "react-icons/ai";
import { BiSolidUser } from "react-icons/bi";
import { FaFacebookF } from "react-icons/fa";
import { HiMiniBars3CenterLeft } from "react-icons/hi2";
import { IoMdMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { MdShoppingCart } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { store } from "../../Helper/Context/Store";
import GetApiCall from "../../Helper/GetApi";
import "../HomeBanner/HomeBanner.css";
import ChangePassword from "../Login/ChangePassword";
import LoginModal from "../Login/LoginModal";
import LogoutModal from "../Login/LogoutModal";
import SocialIcons from "../WrapperComponents/SocialIcons";
import "./MainNavabar.css";
import NewLoginModal from "../Login/NewLoginModal";

export default function MobileNavbar() {
  const history = useHistory();
  // const [] = useState(false)
  const {
    showOfferSection,
    cart,
    setcart,
    setcartamount,
    clientData,
    loggedIn,
    setLoggedIn,
    setCartItems,
    logo,
    setLogo,
    setClientData,
    setStoreStatus,
    setSiteHeader,
  } = useContext(store);
  const { siteHeader } = useContext(store);
  const [loginModalStatus, setLoginModalStatus] = useState(false);
  const [modalSide, setModalSide] = useState("");
  const [openChangePwd, setOpenChangePwd] = useState(false);
  const [canLogout, setCanLogout] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [staticPages, setStaticPages] = useState([]);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    GetApiCall.getRequest("GetStaticPagesList").then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setStaticPages(obj1.data);
        }
      })
    );
    GetApiCall.getRequest("GetSiteSettingsV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setLogo(JSON.parse(obj1.data[0].logo).logo[0].url);
          setClientData(obj1.data);
          setStoreStatus(obj1.data[0].storeStatus);
          setSiteHeader(
            JSON.parse(obj1.data[0]?.site_header)?.menu[0]?.subMenu
          );
        }
      })
    );
  }, []);

  useEffect(() => {
    getCustomerDetails();
  }, [loggedIn]);
  const getCustomerDetails = () => {
    var loginDetails = JSON.parse(localStorage.getItem("CustomerData"));
    if (loginDetails !== null) {
      setLoggedIn(true);
    }
  };
  const onLogout = () => {
    if (localStorage.getItem("CustomerData") !== null) {
      localStorage.removeItem("CustomerData");
      localStorage.removeItem("OrderData");
      setcart(0);
      setcartamount(0);
      setCartItems([]);
      history.push("/");
      setLoggedIn(false);
      setCanLogout(false);
      Notiflix.Loading.remove();
    } else {
      Notiflix.Notify.failure("Something went wrong");
    }
  };

  // login specific functions
  const handleToggleModal = () => {
    setLoginModalStatus(false);
  };
  const handleChangeModalSide = (e) => {
    setModalSide(e);
  };
  const clearItemDetails = () => {
    localStorage.removeItem("itemdetails");
  };
  const handleProfileClick = () => {
    history.push("/customer-profile");
  };
  const changePssword = () => {
    setOpenChangePwd(true);
  };
  // change password specific functions
  const handleTogglePwdModal = () => {
    setOpenChangePwd(false);
  };
  const handleToggleLogout = () => {
    setCanLogout(false);
  };
  const handleOpenCart = () => {
    // if (loggedIn) {
    history.push("/cart");
    // } else {
    //   setModalSide("Log");
    //   setLoginModalStatus(true);
    // }
  };

  const isHomePage = window.location.pathname == "/";
  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }
  }, []);

  const [atBottom, setAtBottom] = useState(false);

  const handleScroll = () => {
    const isBottom =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight;
    setAtBottom(isBottom);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {/* Modals for login, logout and change password */}
      {/* <LoginModal
        isOpen={loginModalStatus}
        handleToggleModal={handleToggleModal}
        modalSide={modalSide}
        handleChangeModalSide={handleChangeModalSide}
      /> */}
      <NewLoginModal
        isOpen={loginModalStatus}
        handleToggleModal={handleToggleModal}
        modalSide={modalSide}
        handleChangeModalSide={handleChangeModalSide}
      />

      <ChangePassword
        isOpen={openChangePwd}
        handdleToggleModal={handleTogglePwdModal}
      />
      <LogoutModal
        show={canLogout}
        onHide={handleToggleLogout}
        onLogout={onLogout}
      />
      {/* main code */}

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="start"
        className="blurbg">
        <Offcanvas.Header closeButton className="ps-2">
          <Offcanvas.Title>
            <Link to="/" onClick={handleClose}>
              <img
                alt="Kaawaii Cosmetics Logo"
                src={logo}
                className="img-fluid nav-logo"
              />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0 mobile-nav-drower">
          <Nav className="d-block offcanvas-menu-color ps-0 pe-1">
            <Link to={"/"} className="nav-link ps-0 pe-1" onClick={handleClose}>
              Home
            </Link>
            <Link
              to="#"
              className="nav-link ps-0 pe-1"
              onClick={() => {
                handleOpenCart();
                handleClose();
              }}>
              Cart
            </Link>

            <NavDropdown
              title="Products"
              id="basic-nav-dropdown"
              className="ps-0">
              <NavDropdown.Item href="/ct/perfumes/1/1">
                <Link
                  to={"/ct/perfume"}
                  className="nav-link ps-0 pe-1"
                  onClick={handleClose}>
                  Perfumes
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="/pct/perfumes">
                <Link
                  to={"/ct/bags"}
                  className="nav-link ps-0 pe-1"
                  onClick={handleClose}>
                  Hamper Bags
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="/ct/perfumes/1/1">
                <Link
                  to={"/ct/accessories"}
                  className="nav-link ps-0 pe-1"
                  onClick={handleClose}>
                  Adorable Accessories
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="/ct/perfumes/1/1">
                <Link
                  to={"/ct/combos"}
                  className="nav-link ps-0 pe-1"
                  onClick={handleClose}>
                  Perfume Combos
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Link
              to={"/our-story"}
              className="nav-link ps-0 pe-1"
              onClick={handleClose}>
              Our Story
            </Link>
            {staticPages.map((page) => (
              <li key={page.fld_id} className="nav-link ps-0 pe-1">
                <Link to={page.fld_route} onClick={() => handleClose()}>
                  {page.fld_page_name}
                </Link>
              </li>
            ))}

            <li className="nav-link ps-0 pe-1">
              <Link to={"/contact-us"} onClick={() => handleClose()}>
                Contact Us
              </Link>
            </li>
          </Nav>
          <div className="d-flex contact-links justify-content-start align-items-center my-2 ps-0 pe-1">
            <IoCall className="me-2 fs-5" />
            <a to={`tel:${clientData[0]?.mobile}`}>{clientData[0]?.mobile}</a>
          </div>
          <div className="d-flex contact-links justify-content-start align-items-center mb-3 ps-0 pe-1">
            <IoMdMail className="me-2 fs-5" />
            <a href={`mailto:${clientData[0]?.email}`}>
              {clientData[0]?.email}
            </a>
          </div>
          <h6 className="ps-1 offcanvas-follow-content mt-3 border-top pt-3 fw-bold">
            Follow us on
          </h6>
          <ul className="d-flex contact-links my-2 ps-0 justify-content-start">
            <SocialIcons
              link={clientData[0]?.facebook}
              target="_blank"
              logo={<FaFacebookF />}
            />
            <SocialIcons
              link={clientData[0]?.instagram}
              target="_blank"
              logo={<AiFillInstagram />}
            />
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      <div
        className={
          scroll ? "mobile-navbar mobile-navbar-fixed" : "mobile-navbar"
        }>
        <HiMiniBars3CenterLeft onClick={handleShow} />

        <ul className="main-nav-right-links contact-links d-flex">
          <li className=" desktop-top-link">
            <Dropdown className="login-dropdown d-none d-lg-flex">
              <Dropdown.Toggle
                // onClick={onProfileClick}
                className="login-icon"
                id="dropdown-basic">
                <BiSolidUser />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {loggedIn === false && (
                  <>
                    <Dropdown.Item
                      onClick={() => {
                        setModalSide("Log");
                        setLoginModalStatus(true);
                        clearItemDetails();
                      }}>
                      Login
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setModalSide("Reg");
                        setLoginModalStatus(true);
                        clearItemDetails();
                      }}>
                      Sign Up
                    </Dropdown.Item>
                  </>
                )}
                {loggedIn === true && (
                  <>
                    <Dropdown.Item onClick={handleProfileClick}>
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item onClick={changePssword}>
                      Change Password
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setCanLogout(true)}>
                      Logout
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="login-dropdown d-flex d-lg-none">
              <Dropdown.Toggle
                // onClick={onProfileClick}
                className="login-icon"
                id="dropdown-basic">
                <BiSolidUser />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {loggedIn === false && (
                  <>
                    <Dropdown.Item
                      onClick={() => {
                        setModalSide("Log");
                        setLoginModalStatus(true);
                        clearItemDetails();
                      }}>
                      Login
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setModalSide("Reg");
                        setLoginModalStatus(true);
                        clearItemDetails();
                      }}>
                      Sign Up
                    </Dropdown.Item>
                  </>
                )}
                {loggedIn === true && (
                  <>
                    <Dropdown.Item onClick={handleProfileClick}>
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item onClick={changePssword}>
                      Change Password
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setCanLogout(true)}>
                      Logout
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </li>
          {cart == 0 && (
            <li
              className={`cursor-pointer me-lg-2 desktop-top-link cart-icon-mobile cart-icon ${atBottom ? "hide-cart-btn" : ""
                }`}
              onClick={handleOpenCart}>
              <MdShoppingCart />
              <span className="cart-qut">| &nbsp; {cart} Items</span>
            </li>
          )}
          {/* new  */}
          {cart > 0 && showOfferSection && (
            <li
              className={`cursor-pointer me-lg-2 desktop-top-link cart-icon flex-column ${atBottom ? "hide-cart-btn" : ""
                }`}
              onClick={handleOpenCart}>
              <div className="">
                <MdShoppingCart />
                <span className="cart-qut">| &nbsp; {cart} Items</span>
              </div>
              <p>Add 1 more from Special Offer to overall Buy 3 Get 1</p>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

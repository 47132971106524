import { Drawer, Radio, Space } from "antd";
import axios from "axios";
import parse from "html-react-parser";
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { BiSolidOffer, BiSolidUser } from "react-icons/bi";
import { CiViewList } from "react-icons/ci";
import { FaAngleRight, FaHome } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { GiSuitcase } from "react-icons/gi";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoCheckmarkDone, IoLocationSharp } from "react-icons/io5";
import { MdCurrencyRupee } from "react-icons/md";
import { RiCoupon2Fill, RiCoupon2Line, RiEBike2Line } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import Noitemfound from "../../assets/img/ITEM NOT FOUND.png";
import freeicon from "../../assets/img/offer/free.png";
import offersmall from "../../assets/img/offer/offer-small.png";
import AddressBookForm from "../../Components/Address/AddressBookForm";
import AddToCart from "../../Components/AddToCart/AddToCart";
import AddToCartQty from "../../Components/AddToCart/AddToCartQty";
import Innerbanner from "../../Components/InnerBanner/InnerBanner";
import NewLoginModal from "../../Components/Login/NewLoginModal";
import { store } from "../../Helper/Context/Store";
import GetApiCall from "../../Helper/GetApi";
import PostApiCall from "../../Helper/PostApi";
import "./Cart.css";
export default function Cart() {
    const history = useHistory();
    const [modalSide, setModalSide] = useState("");
    const [loginModalStatus, setLoginModalStatus] = useState(false);
    const [userEmail, setUserEmail] = useState(null);
    const [showCanvas, setShowCanvas] = useState(false);
    const [cartID, setCartID] = useState(null);
    const [addressData, setAddressData] = useState([]);
    const [shippingAddress, setShippingAddress] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState([]);
    const [offerData, setOfferData] = useState([]);
    const [offerItems, setOfferItems] = useState([]);
    const [allOffers, setAllOffers] = useState([]);
    const [couponId, setCouponId] = useState(null);
    const [couponCode, setCouponCode] = useState(null);
    const [paymentTrxNum, setPaymentTrxNum] = useState("");
    const [couponCard, setCouponCard] = useState(false);
    const [applyCoupon, setApplyCoupon] = useState(false)
    const [offerErrorCode, setOfferErrorCode] = useState("");
    const [offerErrorMessage, setOfferErrorMessage] = useState("");
    const [appliedCoupon, setAppliedCoupon] = useState(null);
    const [showCouponMoreInformation, setShowCouponMoreInformation] = useState("");
    const [enteredCouponCode, setEnteredCouponCode] = useState(null);
    const [couponErrorCodeTextBox, setCouponErrorCodeTextBox] = useState(null);
    const [offerErrorMessageTextBox, setOfferErrorMessageTextBox] = useState("");
    const [title, setTitle] = useState("Home")
    const [isOther, setIsOther] = useState("")
    const [countryId, setCountryId] = useState(0);
    const [stateId, setStateId] = useState(0);
    const [cityId, setCityId] = useState(0);
    const [countryData, setCountryData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [longitude, setLongitude] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [emailRegex] = useState(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const [numRegex] = useState(/^\+?(\d{1,3})?[\s\-]*\(?\d{1,4}\)?[\s\-]*\d{1,4}[\s\-]*\d{1,4}$/);
    const { register: registerShipping, handleSubmit, reset, formState: { errors: errorsShipping }, watch } = useForm();
    const { loggedIn, setLoggedIn, cartData, setCartData, cart, logo, setcart, cartamount, setcartamount, storeStatus } = useContext(store)

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
        getOfferFreeItems()
    };
    const onClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        getCartwebsite();
        GetOfferList();
    }, [])
    useEffect(() => {
        getCustomerAddress()
    }, [loggedIn])




    // Shipping Address form submission
    const onSubmitShipping = (data, e) => {
        Notiflix.Loading.dots("Hang tight! We’re almost set for your payment...")
        if (loggedIn === true) {
            updateCartAddress(selectedAddress?.fld_address_id, selectedAddress?.fld_address_id)
            // handlePayment(cartamount)
        }
        else if (loggedIn === false) {
            guestLogin(data)
            e.target.reset();
        }

    };
    const getCustomerAddress = () => {
        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);
        // if (loggedIn === true) {
        if (login != null || login != undefined) {
            setUserEmail(logindetails[0]?.fld_email)
            PostApiCall.postRequest(
                {
                    whereClause: `where fld_customerid=${logindetails[0]?.fld_customerid}`,
                },
                "GetCustomerAddress"
            ).then((results) =>
                results.json().then((obj) => {
                    if (results.status === 200 || results.status === 201) {
                        setAddressData(obj.data);
                        // Notiflix.Loading.remove();
                    }
                })
            );
        }
    };
    const AddShippingChrg = (id, type = "") => {
        Notiflix.Loading.dots("")
        const det = localStorage.getItem("CustomerData");
        const details = JSON.parse(det);
        PostApiCall.postRequest(
            {
                customerid: details[0].fld_customerid,
                orderid: cartID,
                addressid: id
                // data.fld_address_id,
            },
            "AddShippingChrg"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    setShowCanvas(false)
                    setcartamount(obj.data[0]?.fld_cart_amount)
                    if (type == "guest") {
                        updateCartAddress(id, id, obj.data[0]?.fld_cart_amount, "login")
                        getCartwebsite("guest");
                    } else {
                        Notiflix.Loading.remove()
                        getCartwebsite();
                    }
                }
            })
        );

    }
    const getCartwebsite = (type) => {
        // Notiflix.Loading.circle();
        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);
        var Order = localStorage.getItem("OrderData");
        var Orderdetails = JSON.parse(Order);

        PostApiCall.postRequest(
            {
                orderid:
                    localStorage.getItem("OrderData") == null
                        ? null
                        : Orderdetails[0]?.order_id,
                id:
                    localStorage.getItem("CustomerData") == null
                        ? null
                        : logindetails[0]?.fld_customerid,
            },
            "GetCartWebsite"
        ).then((result) => {
            result.json().then((obj) => {
                if (result.status === 200 || result.status === 201) {
                    setcart(obj?.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
                    // setcartamount(
                    //   obj.data.length == 0 ? 0 : obj.data[0].fld_cart_amount_preview
                    // );
                    // GetOfferList();
                    if (obj.data.length > 0) {
                        const filteredCoupons = offerData.filter(
                            (coupon) => coupon.fld_offerid !== obj.data[0]?.fld_coupon_id
                        );

                        // Use find instead of filter to get a single coupon
                        const selectedCoupon = offerData.find(
                            (coupon) => coupon.fld_offerid === obj.data[0]?.fld_coupon_id
                        );
                        // Update state with the new coupons array
                        setOfferData(
                            (selectedCoupon ? [selectedCoupon] : []).concat(
                                filteredCoupons.map((coupon, index) => ({
                                    ...coupon,
                                    selectedCoupon: selectedCoupon && index === 0,
                                }))
                            )
                        );
                        setCartData(obj.data);
                        setCartID(obj.data[0].fld_cart_id);
                        setCouponId(obj.data[0].fld_coupon_id);
                        setCouponCode(obj.data[0].fld_code);
                        setcartamount(obj.data[0].fld_cart_amount);
                        setPaymentTrxNum(obj.data[0].fld_payment_trx_num);
                        if (type === "guest") {
                        } else {
                            Notiflix.Loading.remove();
                        }
                    }
                    // else {
                    // 	Notiflix.Loading.remove();
                    // }
                }
            });
        });
    };

    const getOfferFreeItems = () => {
        Notiflix.Loading.dots()
        PostApiCall.postRequest(
            {
                cartid: cartID
            },
            "GetFreeOfferItems").then((homepagesetupresult) => {
                homepagesetupresult.json().then((obj) => {
                    setOfferItems(obj.data);
                    Notiflix.Loading.remove();
                });
            });
    }

    // cartitemdetails
    if (cartData.length) {
        var cartItemsDetail = cartData.map((item) => ({
            name: item.fld_variantname,
            price: item.fld_selling_price,
            quantity: item.fld_quantity,
        }));
    }
    function onChangeCountry(country) {
        setCountryId(country.target.value);

        Notiflix.Loading.circle();

        PostApiCall.postRequest(
            {
                countryid: country.target.value,
                Country: countryData[parseInt(country.target.value) + 1].label,
            },
            "GetState"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    setStateId(obj.data[0].value);
                    PostApiCall.postRequest(
                        {
                            stateid: obj.data[0].value,
                        },
                        "GetCity"
                    ).then((resultscity) =>
                        resultscity.json().then((objcity) => {
                            if (resultscity.status === 200 || resultscity.status === 201) {
                                setCityData(objcity.data);
                                setStateData(obj.data);
                                if (objcity.data.length > 0) {
                                    setCityId(objcity.data[0].value);
                                }
                                Notiflix.Loading.remove();
                            }
                        })
                    );
                }
            })
        );
    }

    function onChangeState(state) {
        setStateId(state.target.value);
        Notiflix.Loading.circle();
        PostApiCall.postRequest(
            {
                stateid: state.target.value,
            },
            "GetCity"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    if (obj.data.length > 0) {
                        setCityData(obj.data);
                        setCityId(obj.data[0].value);
                    }
                    Notiflix.Loading.remove();
                }
            })
        );
    }

    function onChangeCity(city) {
        setCityId(city.target.value);
    }

    const handleSendNotification = (orderid) => {
        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);
        PostApiCall.postRequest(
            {
                orderNumber: orderid,
                customerName: logindetails[0]?.fld_name,
                amount: 100,
                location: "",
                orderTime: "10:00AM",
                title: "Hi, you have a new order",
                body: `${logindetails[0]?.fld_name} placed an order`,
            },
            "SendAndroidNotification"
        ).then((result) =>
            result?.json().then((obj3) => {
                if (result.status === 200 || result.status === 201) {
                    Notiflix.Notify.success("Your order has been placed successfully");
                } else {
                    Notiflix.Notify.failure("Oops!", "Something went wrong", "OK");
                }
            })
        );
    };
    // pay to razorpay
    const handlePayment = async (amount, shipAddress) => {
        // if (loggedIn === true) {
        //     if (selectedAddress.length > 0 || shippingAddress == "" || shippingAddress == null) {
        //         Notiflix.Loading.remove();
        //         Notiflix.Notify.failure("Please select your shipping address");
        //         return;
        //     }
        // }
        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);

        try {
            // create order
            const response = await axios.post(
                "https://api.bechofy.in/kaawaii-api/payment",
                { amount }
            );

            const { order } = response.data;


            // Razorpay payment options
            var options = {
                key: process.env.REACT_PAYMENT_KEY || "rzp_live_M3p751xzbN0ucP", // Razorpay Key ID
                amount,
                currency: "INR",
                name: process.env.REACT_NAME || "Kaawaii Cosmetics",
                description: "Kaawaii Cosmetics",
                image: logo,
                order_id: order.id, // Razorpay order ID
                handler: async (response) => {
                    const body = { ...response };

                    Notiflix.Loading.dots("Your transaction is under process..");

                    try {
                        const validateRes = await axios.post(
                            "https://api.bechofy.in/kaawaii-api/RazorPayPaymentStatus",
                            body,
                            { headers: { "Content-Type": "application/json" } }
                        );
                        const jsonRes = validateRes.data;



                        const finalRes = await axios.post(
                            "https://api.bechofy.in/kaawaii-api/RazorPayResponse",
                            {
                                cartid: cartID,
                                customerid:
                                    localStorage.getItem("CustomerData") == null
                                        ? null
                                        : logindetails[0].fld_customerid,
                                amount: cartamount,
                                productinfo: "",
                                firstname:
                                    localStorage.getItem("CustomerData") == null
                                        ? null
                                        : logindetails[0].fld_name,
                                email:
                                    localStorage.getItem("CustomerData") == null
                                        ? null
                                        : logindetails[0].fld_email,
                                mihpayid: jsonRes.status.id,
                                requestid: jsonRes.status.order_id,
                                txnamount: jsonRes.status.amount,
                                errorcode: jsonRes.status.error_code,
                                paymentsource: jsonRes.status.method,
                                errormsg: jsonRes.status.error_description,
                                shippingaddress: shipAddress,
                                billingaddress: shipAddress,
                                status: jsonRes.status.status,
                            }
                        );

                        if (finalRes.status === 200 || finalRes.status === 201) {
                            handleSendNotification(jsonRes.status.order_id);
                            history.push(`/paymentsuccess/${paymentTrxNum}`);
                            Notiflix.Loading.remove();
                        } else {
                            console.log("Payment error");
                            Notiflix.Loading.remove();
                        }
                    } catch (error) {
                        console.error("Error:", error);
                        Notiflix.Loading.remove();
                    }
                },
                prefill: {
                    name: localStorage.getItem("CustomerData") == null
                        ? null
                        : logindetails[0].fld_name,
                    email: localStorage.getItem("CustomerData") == null
                        ? null
                        : logindetails[0].fld_email,
                },
                notes: {
                    cartid: cartID,
                    itemsdetaiils: JSON.stringify(cartItemsDetail),
                    paymentTrxNum: paymentTrxNum,
                },
                theme: {
                    color: "#3ec0e3",
                },
            };

            // open rzp modal
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => {
                const rzp = new window.Razorpay(options);
                rzp.open();
                Notiflix.Loading.remove();
            };
            document.head.appendChild(script);
        } catch (error) {
            console.error("Error in processing payment:", error);
        }
    };
    const guestLogin = (data, type = "", couponData = null) => {
        var Order = localStorage.getItem("OrderData");
        var Orderdetails = JSON.parse(Order);
        PostApiCall.postRequest({
            name: data?.fullName ? data?.fullName : watch("fullName"),
            email: data?.usermemail ? data?.usermemail : watch("usermemail"),
            mobile: data?.usermobile ? data?.usermobile : watch("usermobile"),
            status: "Website",
            cartid:
                localStorage?.getItem("OrderData") == null
                    ? null
                    : Orderdetails[0].order_id,
            signupguest: "Continue",
        }, "AddGuest").then((result) =>
            result.json().then((obj) => {
                if (result.status == 200 || result.status == 201) {
                    Notiflix.Notify.success("Login Successfull")
                    localStorage.setItem("CustomerData", JSON.stringify(obj.data))
                    setModalSide("Log")
                    if (type === "") {
                        addShippingAddress(data)
                    } else if (type == "coupon") {
                        applyOfferCoupon(couponData)
                        Notiflix.Loading.remove()
                    }
                    // Notiflix.Loading.remove()
                    setLoggedIn(true)
                    // handleToggleModal()
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(obj.data);
                }
            })
        );

    }
    const addShippingAddress = (data) => {
        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);

        PostApiCall.postRequest(
            {
                CUSTOMERID: logindetails[0]?.fld_customerid || null,
                addressid: null,
                title: title,
                name: data?.fullName,
                mobileNo: data?.usermobile,
                area: data?.address,
                flatno: data?.flatNo,
                landmark: null,
                gstNumber: null,
                country: countryId,
                state: stateId,
                city: cityId,
                pincode: data?.pincode,
                action: "INSERT",
                longitude: longitude,
                latitude: latitude,
            },
            "AddCustomerAddress"
        ).then((result) =>
            result.json().then((obj) => {
                if (result.status == 200 || result.status == 201) {
                    setShippingAddress(obj.data[0].id)
                    AddShippingChrg(obj.data[0].id, "guest")
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(obj.data);
                }
            })
        );

    }
    const updateCartAddress = (billAddress, shipAddress, amount, type = "") => {
        Notiflix.Loading.dots();
        if (type !== "login") {
            if (shippingAddress == "" || shippingAddress == null) {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("Please select your shipping address");
                return;
            }
        }
        PostApiCall.postRequest(
            {
                cartid: cartID,
                billingaddress: billAddress == null ? shippingAddress : billAddress,
                shippingaddress: shipAddress == null ? shippingAddress : shipAddress,
            },
            "UpdateCartAddAddress"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    handlePayment(amount ? amount : cartamount, shipAddress == null ? shippingAddress : shipAddress);
                    // if (type !== "login") {
                    //     Notiflix.Loading.remove()

                    // }
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure("Something went wrong")
                }
            })
        );
    };
    const applyOfferCoupon = (data) => {
        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);
        PostApiCall.postRequest(
            {
                orderid: cartID,
                couponid: data.fld_offerid,
                stateid: localStorage.getItem("ChangedPincode"),
                customerid:
                    localStorage.getItem("CustomerData") == null
                        ? null
                        : logindetails[0]?.fld_customerid,
                status: "apply",
            },
            "ApplyOffer"
        ).then((result) =>
            result.json().then((obj3) => {
                if (obj3.data) {
                    if (obj3.data.length != 0) {
                        Notiflix.Loading.remove();
                        setOfferErrorCode(obj3.data[0].errorcode);
                        setOfferErrorMessage(obj3.data[0].error);
                        setAppliedCoupon(data.fld_code);
                        getCartwebsite();
                    } else {
                        Notiflix.Loading.remove();
                    }
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(
                        "Soemthing went wrong. Please try again."
                    );
                }
            })
        )
    }

    const removeCoupon = (cartid, couponid, type = "") => {
        var login = localStorage.getItem("CustomerData");
        var logindetails = JSON.parse(login);
        Notiflix.Loading.circle();
        PostApiCall.postRequest(
            {
                orderid: cartid,
                couponid: couponid,
                stateid: localStorage.getItem("ChangedPincode"),
                customerid:
                    localStorage.getItem("CustomerData") == null
                        ? null
                        : logindetails[0]?.fld_customerid,
                status: "remove",
            },
            "ApplyOffer"
        ).then((result) =>
            result.json().then((obj3) => {
                if (result.status === 200 || result.status === 201) {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.success("Coupon removed successfully");
                    setOfferErrorCode("");
                    setOfferErrorMessage("");
                    getCartwebsite();
                    if (type === "logout") {
                        setLoggedIn(false);
                        localStorage.removeItem("CustomerData");
                        setApplyCoupon(false)
                        setCouponCode(null)
                    }
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(obj3.data);
                }
            })
        );
    };
    const GetOfferList = () => {
        GetApiCall.getRequest("GetOfferList").then((resultdes) =>
            resultdes.json().then((obj) => {
                setOfferData(
                    obj.data.filter((value) => value.fld_showonwebsite == "Active")
                );
                setAllOffers(obj.data.filter((value) => value.fld_showonwebsite == "Active"))
            })
        );
    };



    useEffect(() => {
        // Notiflix.Loading.circle();

        // GetOfferList();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        Notiflix.Loading.init({
            svgColor: "#0f3d74",
        });
        navigator.geolocation.getCurrentPosition((position) => {
            var Latitude = position.coords.latitude;
            var Longitude = position.coords.longitude;
            setLongitude(Longitude);
            setLatitude(Latitude);
        });

        GetApiCall.getRequest("GetCountry").then((resultdes) =>
            resultdes.json().then((obj) => {
                setCountryData(obj.data);
                if (obj.data.length != 0) {
                    setCountryId(101);
                }
                PostApiCall.postRequest(
                    {
                        countryid: 101,
                    },
                    "GetState"
                ).then((results) =>
                    results.json().then((objstate) => {
                        if (results.status === 200 || results.status === 201) {
                            if (objstate.data.length != 0) {
                                // setStateId(objstate.data[0].value);
                                setStateId(4008)
                                setStateData(objstate.data);
                            }

                            PostApiCall.postRequest(
                                {
                                    stateid: 4008
                                    // stateid: objstate.data[0].value,
                                },
                                "GetCity"
                            ).then((resultscity) =>
                                resultscity.json().then((objcity) => {
                                    if (resultscity.status === 200 || resultscity.status === 201) {
                                        if (objcity.data.length != 0) {
                                            setCityId(objcity.data[303].value);
                                            setCityData(objcity.data);
                                        }
                                    }
                                })
                            );
                        }
                    })
                );
            })
        );
    }, []);

    const handleChangeModalSide = (e) => {
        setModalSide(e);
        // setModalSide("Log");
    };
    const handleToggleModal = () => {
        setLoginModalStatus(false);
    };
    const handleCloseCanvas = (id = "") => {
        setShowCanvas(false);
        if (id != "") {
            setShippingAddress(id)
            AddShippingChrg(id)
            getCustomerAddress()
        }
    };
    const handleToggle = () => {
        setApplyCoupon(false)
        setCouponCard(false);
        setCouponErrorCodeTextBox(null);
        setOfferErrorMessage("");
        setEnteredCouponCode(null);
    };

    const displaysize = window.innerWidth
    const showCouponsSection = !cartData.filter((items) => items.item_price === 0).length;
    return (
        <>
            {/* <LoginModal
                isOpen={loginModalStatus}
                handleToggleModal={handleToggleModal}
                modalSide={modalSide}
                handleChangeModalSide={handleChangeModalSide}
            /> */}
            <NewLoginModal
                isOpen={loginModalStatus}
                handleToggleModal={handleToggleModal}
                modalSide={modalSide}
                handleChangeModalSide={handleChangeModalSide}
            />
            <Innerbanner />
            {cart > 0 ? (
                <div className="container-fluid px-lg-4 mb-lg-4 mt-lg-5">
                    <form onSubmit={handleSubmit(onSubmitShipping)} className="row">
                        <div className="col-lg-6">
                            {loggedIn === false ?
                                <div className="row mb-3">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="sub-heading mb-0">Contact</h4><div
                                            className="theme-btn border-0" onClick={() => {
                                                setModalSide("Log");
                                                setLoginModalStatus(true);
                                            }}>Login</div>
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: "10px" }}>
                                        <label htmlFor="usermemail">Email <span className="text-danger">*</span></label>
                                        <input
                                            className="form-control"
                                            id="usermemail"
                                            type="text"
                                            value={watch("usermemail") || ''}
                                            {...registerShipping('usermemail', {
                                                required: 'Email address is required',
                                                pattern: {
                                                    value: emailRegex,
                                                    message: 'Please enter a valid email address',
                                                }
                                            })}
                                        />
                                        {errorsShipping.usermemail && <small className="text-danger">{errorsShipping.usermemail.message}</small>}
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: "10px" }}>
                                        <label htmlFor="usermobile">Mobile Number <span className="text-danger">*</span></label>
                                        <input
                                            className="form-control"
                                            id="usermobile"
                                            type="text"
                                            value={watch("usermobile") || ''}
                                            {...registerShipping('usermobile', {
                                                required: 'Mobile number is required',
                                                pattern: {
                                                    value: numRegex,
                                                    message: 'Please enter a valid mobile number',
                                                }
                                            })}
                                        />
                                        {errorsShipping.usermobile && <small className="text-danger">{errorsShipping.usermobile.message}</small>}
                                        <p><small><i>May be used to assist for delivery</i></small></p>
                                    </div>
                                </div>
                                :
                                <div className="cartcheckout-accordion mb-3">
                                    <div className="d-flex ant-collapse-header align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <span className="cart-accordian-icons">
                                                <BiSolidUser />
                                            </span>
                                            <div>
                                                <p className="mb-0 text-dark">
                                                    {userEmail}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="theme-btn " onClick={() => {
                                            if (localStorage.getItem("CustomerData") !== null) {
                                                // localStorage.removeItem("OrderData");
                                                //   setcart(0);
                                                //   setcartamount(0);
                                                //   setCartItems([]);
                                                // setLoggedIn(false);
                                                // localStorage.removeItem("CustomerData");
                                                // setApplyCoupon(false)
                                                // setCouponCode(null)
                                                // setCanLogout(false);
                                                reset();
                                                if (cartData[0]?.fld_coupon_id) {
                                                    removeCoupon(cartID, couponId, "logout")
                                                } else {
                                                    setLoggedIn(false);
                                                    localStorage.removeItem("CustomerData");
                                                }
                                                Notiflix.Loading.remove();
                                            } else {
                                                Notiflix.Notify.failure("Something went wrong");
                                            }
                                        }}>Log Out</div>
                                    </div>
                                </div>}
                            {loggedIn === true ?
                                <div className="cartcheckout-accordion">
                                    <div className="d-flex ant-collapse-header align-items-center">
                                        <span className="cart-accordian-icons">
                                            <IoLocationSharp />
                                        </span>
                                        <h4 className="main-heading mb-0">Shipping Address</h4>
                                    </div>
                                    <>
                                        <div className="col-12 d-flex justify-content-between align-items-center p-2 ">
                                            <div className="w-100 p-2 d-flex justify-content-between">
                                                <h4>My Address List</h4>
                                                <button
                                                    type="button"
                                                    className="theme-btn border-0 py-1"
                                                    onClick={() => {
                                                        setShowCanvas(true);
                                                    }}>
                                                    Add New Address
                                                </button>
                                            </div>
                                        </div>
                                        {/* display the existing addresses*/}

                                        <div className="col-12">
                                            <div className="row m-0 w-100">
                                                {addressData.length > 0 ? (
                                                    <>
                                                        {addressData.map((data, index) => (
                                                            <>
                                                                <div className="Col-12">
                                                                    <div className="boxes">
                                                                        <div className="d-flex">
                                                                            <div className="p-2 bg-light text-center gap-2 pe-3 d-flex align-items-center">
                                                                                <input
                                                                                    type="radio"
                                                                                    checked={
                                                                                        shippingAddress !== null ? shippingAddress === data.fld_address_id :
                                                                                            selectedAddress.fld_address_id ===
                                                                                                data.fld_address_id
                                                                                                ? true
                                                                                                : false
                                                                                    }
                                                                                    onChange={() => {
                                                                                        setSelectedAddress(data);
                                                                                        setShippingAddress(
                                                                                            data.fld_address_id
                                                                                        );
                                                                                        // setSelectedBillingAddress(data);
                                                                                        // setBillingAddress(
                                                                                        //     data.fld_address_id
                                                                                        // );
                                                                                        AddShippingChrg(data.fld_address_id)
                                                                                    }}
                                                                                />
                                                                                <div>
                                                                                    {
                                                                                        data.fld_address_type == "Home" ? (
                                                                                            <span className="cart-address-icons">
                                                                                                <FaHome />
                                                                                            </span>
                                                                                        ) :
                                                                                            data.fld_address_type == "Office" ? (
                                                                                                <span className="cart-address-icons">
                                                                                                    <GiSuitcase />
                                                                                                </span>
                                                                                            ) : (
                                                                                                <span className="cart-address-icons">
                                                                                                    <FaLocationDot />
                                                                                                </span>
                                                                                            )
                                                                                    }
                                                                                    <p className="mb-0 fs-6">
                                                                                        {data.fld_address_type}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="p-2">
                                                                                <p>
                                                                                    <b>{data.fld_contact_name}</b>
                                                                                </p>
                                                                                <p>{data.fld_contact_number}</p>
                                                                                <p>
                                                                                    {data.fld_address_1},{" "}
                                                                                    {data.fld_address_2}, <br className="d-lg-none d-block" />{data.CityName},{" "}
                                                                                    {data.fld_landmark},{" "} <br className="d-lg-none d-block" />{data.StateName} -{" "}
                                                                                    {data.fld_pincode}, <span className="text-uppercase">{data.CountryName}</span>{" "}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <p className="text-center">No Address Found</p>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                </div> : ""}
                            <div className="row">
                                {loggedIn === false ? <><h4 className="sub-heading mb-0">Delivery</h4>
                                    <div className="col-md-12 center">
                                        <div className="form-group">
                                            <label for="validationCustom01">
                                                Save Address as <span className="text-danger">*</span>
                                            </label>
                                            <br />
                                            <div className="address-types-btn cart-page-address-btns p-2 d-flex align-items-center">
                                                <Radio.Group defaultValue={1} onChange={(e) => {
                                                    if (e.target.value == 1) {
                                                        setIsOther("")
                                                        setTitle("Home");
                                                    } else if (e.target.value == 2) {
                                                        setIsOther("")
                                                        setTitle("Work");
                                                    } else {
                                                        setTitle("");
                                                        setIsOther("Other")
                                                    }
                                                }} >
                                                    <Space direction="horizontal">
                                                        <Radio value={1}><span className="address-offcanvas-icons me-2"><FaHome /></span>
                                                            <p className="me-2 mb-0 mt-1">Home</p></Radio>
                                                        <Radio value={2}><span className="address-offcanvas-icons me-2"><GiSuitcase /></span>
                                                            <p className="me-2 mb-0 mt-1"> Work</p></Radio>
                                                        <Radio value={3}><span className="address-offcanvas-icons me-2"><FaLocationDot /></span>
                                                            <p className="me-2 mb-0 mt-1">Others</p></Radio>
                                                    </Space>
                                                </Radio.Group>


                                            </div>




                                        </div>
                                        {isOther == "Other" &&
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>
                                                        Save Address As<span className="text-danger">*</span>
                                                        <div className="form-group mt-2 mb-3 col-12">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={title}
                                                                placeholder="Save As"
                                                                onChange={(text) => {
                                                                    setTitle(text.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group mb-3">
                                        <label for="validationCustom01">
                                            Country{" "}<span className="text-danger">*</span>
                                        </label>
                                        <select
                                            className="form-select my-1"
                                            id="inlineFormCustomSelectPref"
                                            name="UserType"
                                            value={countryId}
                                            onChange={onChangeCountry}>
                                            {countryData.map((schedule) => (
                                                <option
                                                    key={schedule.label}
                                                    value={schedule.value}>
                                                    {schedule.label}
                                                </option>
                                            ))}

                                        </select>
                                    </div>
                                    {/* <form onSubmit={handleSubmitShipping(onSubmitShipping)} className="row"> */}
                                    <div className="col-12 my-2">
                                        <label htmlFor="fullNameShipping">Name <span className="text-danger">*</span></label>
                                        <input
                                            className="form-control"
                                            id="fullNameShipping"
                                            type="text"
                                            value={watch("fullNameShipping")}
                                            {...registerShipping('fullName', { required: 'Name is required' })}
                                        />
                                        {errorsShipping.fullName && <small className="text-danger">{errorsShipping.fullName.message}</small>}
                                    </div>

                                    <div className="col-12 mb-2">
                                        <label htmlFor="addressShipping">Area / Street / Sector / Village<span className="text-danger">*</span></label>
                                        <input
                                            className="form-control"
                                            id="addressShipping"
                                            type="text"
                                            value={watch("addressShipping")}
                                            {...registerShipping('address', { required: 'Address is required' })}
                                        />
                                        {errorsShipping.address && <small className="text-danger">{errorsShipping.address.message}</small>}
                                    </div>

                                    <div className="col-12 mb-2">
                                        <label htmlFor="flatNoShipping">Flat / House No. / Floor / Building <span className="text-danger">*</span></label>
                                        <input
                                            className="form-control"
                                            id="flatNoShipping"
                                            type="text"
                                            value={watch("flatNoShipping")}
                                            {...registerShipping('flatNo', { required: 'Flat No. is required' })}
                                        />
                                        {errorsShipping.flatNo && <small className="text-danger">{errorsShipping.flatNo.message}</small>}
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label for="validationCustom01">
                                                State{" "}<span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-select my-1 me-sm-2"
                                                id="inlineFormCustomSelectPref"
                                                name="UserType"
                                                value={stateId}
                                                onChange={onChangeState}>
                                                {stateData.map((schedule) => (
                                                    <option
                                                        key={schedule.label}
                                                        value={schedule.value}>
                                                        {schedule.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label for="validationCustom01">
                                                City{" "}<span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-select my-1 me-sm-2"
                                                id="inlineFormCustomSelectPref"
                                                name="UserType"
                                                value={cityId}
                                                onChange={onChangeCity}>
                                                {cityData.map((schedule) => (
                                                    <option
                                                        key={schedule.label}
                                                        value={schedule.value}>
                                                        {schedule.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="pincodeShipping">Pincode <span className="text-danger">*</span></label>
                                        <input
                                            className="form-control my-1"
                                            id="pincodeShipping"
                                            type="text"
                                            value={watch("pincodeShipping")}
                                            {...registerShipping('pincode', {
                                                required: 'Pincode is required'
                                            })}
                                            maxLength={6}
                                        />
                                        {errorsShipping.pincode && <small className="text-danger">{errorsShipping.pincode.message}</small>}
                                    </div>
                                    {/* <div>
											<button type="submit" className="theme-btn border-0 mt-1">Submit Shipping Address</button>
										</div> */}
                                    {/* </form> */}
                                </> : ""}

                                <div>
                                    <h4 className="sub-heading mb-0 mt-3">
                                        Payment</h4>
                                    <div>All transactions are secure and encrypted.</div>
                                    <div className="border rounded overflow-hidden mt-3">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center p-2 bg-light">
                                            <p className="mb-0">Razorpay Secure (UPI, Cards, Wallets, NetBanking)
                                            </p>
                                        </div>
                                        <div className="text-center px-3 px-lg-5 py-5">
                                            <p className="text-center">
                                                After clicking “Confirm Order”, you will be redirected to Razorpay Secure (UPI, Cards, Wallets, NetBanking) to complete your purchase securely.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {storeStatus == "Active" && (
                                    <button
                                        type="submit"
                                        className={
                                            cartamount < 1
                                                ? "theme-btn-disabled border-0 w-100 d-none d-lg-block mt-3"
                                                : "mt-3 theme-btn border-0 w-100 d-none d-lg-block py-2"
                                        }
                                        // onClick={() => handlePayment(cartamount)}
                                        disabled={cartamount < 1 ? true : false}
                                    >
                                        {" "}
                                        Confirm Order
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 mb-5 pb-lg-5">
                            <div
                                className="order-summary sticky-top mt-4 mt-lg-0">
                                <div>
                                    <h4 className="sub-heading mb-0">Order Summary</h4>
                                    <div className="checkout-bx pb-0">
                                        {cartData?.length > 0
                                            ? cartData.map((product, index) => (
                                                <div className="position-relative">
                                                    <div className="row position-relative mb-2 py-3 pb-md-0 rounded w-100 m-0">
                                                        <div className="col-4 col-md-2">
                                                            <div className="row">
                                                                {console.log(cartData)}
                                                                <div className="col-12">
                                                                    {product.item_price === 0 ?
                                                                        <>
                                                                            <img src={product.VariantImage} alt={product.fld_itemname} className="checkout-img mb-lg-3"></img>
                                                                            <span className={product.item_type == null ? "d-none" : "veg-nonveg-icon"}></span>
                                                                        </>

                                                                        : <Link to={`${process.env.PUBLIC_URL}/p/${product.fld_route?.replace(/\s/g, "-").replace('/', '')}`}>
                                                                            <img src={product.VariantImage} alt={product.fld_itemname} className="checkout-img mb-lg-3"></img>
                                                                            <span className={product.item_type == null ? "d-none" : "veg-nonveg-icon"}></span>
                                                                        </Link>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-8 col-md-10">
                                                            <div className="row">
                                                                <div className="col-md-6 cproduct-name cproduct-card-name">
                                                                    <div>
                                                                        <span className="d-block">
                                                                            {product.fld_categoryname}
                                                                        </span>
                                                                        {product.item_price === 0 ? <>{product.fld_variantname}</> : <Link className="fw-bold" to={`${process.env.PUBLIC_URL}/p/${product.fld_route?.replace(/\s/g, "-").replace('/', '')}`}>
                                                                            {product.fld_variantname}
                                                                        </Link>}
                                                                        <div className="c-price">
                                                                            <span className="d-flex">
                                                                                {product.item_price === 0 ?
                                                                                    <div className="prod-price me-2 mb-0 pb-0">
                                                                                        <span className="mx-1">FREE</span>
                                                                                        <del>
                                                                                            ₹{" "}
                                                                                            {parseFloat(
                                                                                                product.fld_mrp
                                                                                            ).toFixed(0)}
                                                                                        </del>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="prod-price me-2 mb-0 pb-0">
                                                                                        ₹{" "}
                                                                                        {parseFloat(
                                                                                            product.fld_website_discount_price *
                                                                                            product.fld_quantity
                                                                                        ).toFixed(0)}
                                                                                    </div>}
                                                                                {product.fld_mrp >
                                                                                    product.fld_website_discount_price && (
                                                                                        <del> {" "}
                                                                                            ₹{" "} {parseFloat(product.fld_mrp * product.fld_quantity).toFixed(0)}
                                                                                        </del>
                                                                                    )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    {product.item_price !== 0 &&
                                                                        <div className="d-none d-md-block cquantity mt-md-3">
                                                                            <AddToCartQty
                                                                                itemid={product.fld_variantid}
                                                                                quantity={product.fld_quantity}
                                                                                cartlineid={product.fld_cart_dtl_id}
                                                                                shippingAddress={shippingAddress}
                                                                            />
                                                                        </div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {product.item_price != 0 &&
                                                            <div className="col-12 d-block d-md-none cquantity">
                                                                <div className="quantity">
                                                                    <AddToCartQty
                                                                        itemid={product.fld_variantid}
                                                                        quantity={product.fld_quantity}
                                                                        cartlineid={product.fld_cart_dtl_id}
                                                                        shippingAddress={shippingAddress}
                                                                    />
                                                                </div>
                                                            </div>}
                                                    </div>
                                                    <hr />
                                                </div>
                                            ))
                                            : ""}
                                    </div>
                                    {cartData.map((product, i) => {
                                        if (i === 0) {
                                            return (
                                                <table className="order-summary-table">

                                                    {cartData[0]?.fld_isEligibleForFreeItem === "true" && (
                                                        <tr>
                                                            <td className="border-0 w-100" colSpan={3}>
                                                                <div className="d-flex justify-content-between align-items-center w-100 coupon-card-box-offer cursor-pointer">
                                                                    <div className="d-flex align-items-center cursor-pointer apply-coupon-offer" onClick={showDrawer}>
                                                                        <img src={offersmall} className="img-fluid" />
                                                                        <div className="offer-icon">
                                                                            <div className="ms-2 fw-bold mb-0 text-dark offer">Claim your {cartData[0]?.fld_offer_name} deal
                                                                            </div>
                                                                            <div className="ms-2">
                                                                                Add 1 FREE item
                                                                                <FaAngleRight />

                                                                            </div>

                                                                        </div>
                                                                        <div className="free-icon-offer">
                                                                            <img src={freeicon} className="img-fluid" />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {showCouponsSection && allOffers.length > 0 && (
                                                        allOffers.length > 0 && (
                                                            <tr>
                                                                <td className="border-0 w-100" colSpan={3}>
                                                                    <div onClick={() => {
                                                                        if (couponCode == null) {
                                                                            setCouponCard(true);
                                                                        }
                                                                    }} className="d-flex justify-content-between align-items-center w-100 coupon-card-box cursor-pointer">
                                                                        <div className="d-flex align-items-center cursor-pointer apply-coupon">
                                                                            <RiCoupon2Fill />
                                                                            <div className="ms-2 fw-bold mb-0 text-dark">
                                                                                {couponCode == null ? "Apply Coupon" : couponCode}
                                                                            </div>
                                                                        </div>
                                                                        {couponCode == null ? (
                                                                            <>
                                                                                {offerData.length > 0 && (
                                                                                    <p className="mb-0 text-dark text-end">
                                                                                        {offerData.length} Coupons Available
                                                                                    </p>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <p
                                                                                style={{ cursor: "pointer" }}
                                                                                className="removeCoupon"
                                                                                onClick={() => {
                                                                                    removeCoupon(cartID, product.fld_coupon_id, "");
                                                                                }}>
                                                                                {" "}
                                                                                Remove
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    <tr>
                                                        <td>
                                                            <CiViewList />
                                                            Items Total
                                                        </td>
                                                        <td className="text-end">
                                                            {" "} <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                                                            {parseFloat(product.fld_total_net_amount).toFixed(0)}{" "}
                                                        </td>
                                                    </tr>
                                                    {(product.fld_cart_discount_amount ||
                                                        product.fld_cart_discount_amount != 0) && (
                                                            <tr>
                                                                <td> <BiSolidOffer /> Offer Discount{" "}
                                                                </td>
                                                                <td className="text-end">
                                                                    {" "} <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                                                                    {product.fld_cart_discount_amount}{" "}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    {product.fld_coupon_discount != null &&
                                                        product.fld_coupon_discount != 0 && (
                                                            <tr>
                                                                <td> <RiCoupon2Line /> Coupon Discount{" "}
                                                                </td>
                                                                <td className="text-end">
                                                                    {" "} <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                                                                    {product.fld_coupon_discount}{" "}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    <tr>
                                                        <td className="d-flex align-items-center">
                                                            <RiEBike2Line />
                                                            <span className="delivery-charges">
                                                                Delivery Charges
                                                            </span>{" "}
                                                        </td>

                                                        <td className="text-end">
                                                            {" "} {product.fld_shipping_charge == null || product.fld_shipping_charge == 0 || shippingAddress == "" || shippingAddress == null ? (
                                                                "To Be Determined"
                                                            ) : (
                                                                <><i
                                                                    className="fa fa-inr"
                                                                    aria-hidden="true"></i>{" "}
                                                                    {parseFloat(
                                                                        product.fld_shipping_charge
                                                                    ).toFixed(2)}
                                                                </>
                                                            )}{" "}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <HiOutlineBuildingLibrary /> GST
                                                        </td>
                                                        <td className="text-end">
                                                            {" "} <i className="fa fa-inr" aria-hidden="true"></i>
                                                            {parseFloat(product.fld_total_gst).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                    <tr className="grand-total">
                                                        <td style={{ paddingTop: "0px !important" }}>
                                                            <p className="fw-bold">Grand Total</p>
                                                        </td>
                                                        <td className="text-end fs-6 fw-bold">
                                                            {" "}  <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                                                            {parseFloat(product.fld_cart_amount).toFixed(2)}{" "}
                                                            <br />
                                                            <i>(Inclusive of all Taxes)</i>
                                                        </td>
                                                    </tr>
                                                </table>
                                            );
                                        }
                                    })}
                                    <p className="mb-0 mt-1">
                                        (Delivery typically takes 4 to 6 days.)
                                    </p>
                                    {storeStatus == "Active" && (
                                        <button
                                            type="submit"
                                            className={cartamount < 1
                                                ? "theme-btn-disabled border-0 w-100 d-lg-none mt-3"
                                                : "mt-3 theme-btn border-0 w-100 d-lg-none py-2"}
                                            // onClick={() => handlePayment(cartamount)}
                                            disabled={cartamount < 1 ? true : false}
                                        >
                                            {" "}
                                            Confirm Order
                                        </button>
                                    )}
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            ) : (
                <div className="blank-section w-100 h-100 d-flex align-items-center justify-content-center flex-column py-5 my-md-5">
                    <div className="empty-icon fs-1 text-center ">
                        <img src={Noitemfound} className="w-25 mb-2" />
                    </div>
                    <p className="mb-md-4 mb-3">No Items in available in your cart</p>
                    <div>
                        <a href="/" className="theme-btn w-auto d-block">
                            Shop Now
                        </a>
                    </div>
                </div>
            )}
            <Modal
                show={couponCard == true ? true : false}
                // show={couponCard}
                centered
                fullscreen="xl"
                size="md"
                scrollable="true"
                className="coupon-modal"
                onHide={handleToggle}>
                <Modal.Header
                    closeButton
                    className="pb-0 border-0"></Modal.Header>
                <Modal.Body className="py-0">
                    <div className="row">
                        <div className="col-12 text-center mb-4">
                            <RiCoupon2Fill className="main-svg" />
                            <div className="section-title mt-0">
                                <h4 className="section-title-category-section">
                                    Coupons and Offers
                                    <div className="title-divider"></div>
                                </h4>
                            </div>
                        </div>
                        <div className="col-12 mb-2 apply-coupon-input">
                            <input
                                type="text"
                                placeholder="Enter Coupon Code"
                                className="form-control mb-0"
                                value={enteredCouponCode}
                                onChange={(enteredcouponcode) => {
                                    setEnteredCouponCode(enteredcouponcode.target.value);
                                }}></input>
                            {couponErrorCodeTextBox &&
                                <p className="invalid-coupon-code-textbox">
                                    {couponErrorCodeTextBox}
                                </p>
                            }
                            {offerErrorMessageTextBox &&
                                <p className="invalid-coupon-code-textbox-color">
                                    {offerErrorMessageTextBox}
                                </p>
                            }
                        </div>
                        <div className="col-12 apply-coupon-button">
                            <button
                                className="theme-btn border-0 my-3 py-2 w-100 text-center"
                                onClick={() => {
                                    var login = localStorage.getItem("CustomerData");
                                    var logindetails = JSON.parse(login);

                                    if (enteredCouponCode != null) {
                                        setCouponErrorCodeTextBox(null);
                                        setOfferErrorMessageTextBox("");
                                        {
                                            PostApiCall.postRequest(
                                                {
                                                    whereClause: `where UPPER(fld_code) = UPPER('${enteredCouponCode}') AND cast(getdate() as date) between cast(fld_startdate as date) and cast(fld_enddate as date)`,
                                                },
                                                "SearchForOfferCode"
                                            ).then((results) =>
                                                results.json().then((obj) => {
                                                    if (results.status == 200 || results.status == 201) {
                                                        if (obj.data.length != 0) {
                                                            PostApiCall.postRequest(
                                                                {
                                                                    orderid: cartID,
                                                                    couponid: obj.data[0]?.fld_offerid,
                                                                    stateid:
                                                                        localStorage.getItem("ChangedPincode") ==
                                                                            null
                                                                            ? null
                                                                            : localStorage.getItem("ChangedPincode"),
                                                                    customerid:
                                                                        localStorage.getItem("CustomerData") == null
                                                                            ? null
                                                                            : logindetails[0]?.fld_customerid,
                                                                    status: "apply",
                                                                },
                                                                "ApplyOffer"
                                                            ).then((result) =>
                                                                result.json().then((obj3) => {
                                                                    if (obj3.data) {
                                                                        if (obj3.data.length != 0) {
                                                                            Notiflix.Loading.remove();
                                                                            setOfferErrorCode(obj3.data[0].errorcode);
                                                                            setOfferErrorMessageTextBox(
                                                                                obj3.data[0].error
                                                                            );
                                                                            setAppliedCoupon(enteredCouponCode);

                                                                            getCartwebsite();
                                                                        } else {
                                                                            Notiflix.Loading.remove();
                                                                        }
                                                                    } else {
                                                                        Notiflix.Loading.remove();
                                                                        Notiflix.Notify.failure(
                                                                            "Soemthing went wrong. Please try again."
                                                                        );
                                                                    }
                                                                })
                                                            );
                                                        } else {
                                                            setOfferErrorMessageTextBox(
                                                                "Coupon does not exist"
                                                            );
                                                        }
                                                    }
                                                })
                                            );
                                        }
                                    } else {
                                        Notiflix.Notify.failure("Please enter Coupon Code");
                                    }
                                }}>
                                Apply Coupon
                            </button>
                        </div>
                    </div>
                    <div
                        className="rgbox-bottom text-center mb-4"
                        style={{
                            display: offerData.length != 0 ? "block" : "none",
                        }}>
                        <p className="text-center">
                            <label>OR</label>
                        </p>
                    </div>
                    <div className="coupon-main-box">
                        {offerData?.map((data, i) => {
                            return (
                                <div
                                    className={
                                        showCouponMoreInformation == data.fld_offerid
                                            ? "coupon-card coupon-card-active mb-3 d-flex flex-column align-content-between"
                                            : "coupon-card mb-3 d-flex flex-column align-content-between"
                                    }
                                    key={i}>
                                    <div className="px-3 pt-3">
                                        <p className="text-dark text-center fw-bold pb-1">
                                            {data.fld_name}
                                        </p>
                                        <hr className="mt-2 border-dark" />
                                        <p className="coupon-terms-condition">{data.fld_caption}</p>
                                        <div className="d-flex justify-content-between align-items-center my-2">
                                            <p className="coupon-card-code">{data.fld_code}</p>
                                            <span
                                                className="view-more cursor-pointer text-dark text-end"
                                                style={{
                                                    display:
                                                        showCouponMoreInformation == false
                                                            ? "block"
                                                            : "none",
                                                }}
                                                onClick={() => {
                                                    setShowCouponMoreInformation(data.fld_offerid);
                                                }}>
                                                View Details <IoIosArrowDown />
                                            </span>
                                        </div>
                                        <p
                                            className={
                                                appliedCoupon == data.fld_code
                                                    ? "coupon-terms-condition mb-5"
                                                    : "d-none"
                                            }
                                            style={{
                                                color: offerErrorCode == "1" ? "#fa4a5b" : "#60b246",
                                            }}>
                                            {appliedCoupon == data.fld_code ? offerErrorMessage : ""}
                                        </p>
                                        <div
                                            className="coupon-terms-condition ms-2"
                                            style={{
                                                display:
                                                    showCouponMoreInformation == data.fld_offerid
                                                        ? "block"
                                                        : "none",
                                            }}>
                                            <hr className="border-dark" />
                                            <p className="text-dark">Terms and Conditions</p>
                                            <p
                                                className={
                                                    showCouponMoreInformation == data.fld_offerid
                                                        ? "terms-show"
                                                        : ""
                                                }>
                                                {parse(data.fld_termscondition)}
                                            </p>
                                            <span
                                                className="cursor-pointer mt-3 text-dark d-block text-end"
                                                onClick={() => {
                                                    setShowCouponMoreInformation("");
                                                }}>
                                                View Less <IoIosArrowUp />
                                            </span>
                                        </div>
                                        {couponCode == data.fld_code && (
                                            <p
                                                className="text-danger"
                                                style={{
                                                    display:
                                                        offerErrorMessage !=
                                                            "Coupon applied successfully" ||
                                                            offerErrorMessage != ""
                                                            ? "none"
                                                            : "block",
                                                }}>
                                                {offerErrorMessage}
                                            </p>
                                        )}
                                    </div>
                                    <div className="text-center apply-coupon-btn mt-2">
                                        <button
                                            className="btn align-items-center place-order text-white w-100"
                                            onClick={() => {

                                                Notiflix.Loading.circle();
                                                if (loggedIn === false) {
                                                    if (watch("usermemail") == "") {
                                                        Notiflix.Loading.remove()
                                                        Notiflix.Notify.failure("Please enter email address")
                                                        return;
                                                    }
                                                    if (!emailRegex.test(watch("usermemail"))) {
                                                        Notiflix.Loading.remove()
                                                        Notiflix.Notify.failure("Please enter valid email address")
                                                        return;

                                                    }
                                                    if (watch("usermobile") == "") {
                                                        Notiflix.Loading.remove()
                                                        Notiflix.Notify.failure("Please enter mobile number")
                                                        return;
                                                    }
                                                    if (!numRegex.test(watch("usermobile"))) {
                                                        Notiflix.Loading.remove()
                                                        Notiflix.Notify.failure("Please enter a valid mobile number")
                                                        return;
                                                    }
                                                    guestLogin("", "coupon", data);

                                                }
                                                if (loggedIn === true) {
                                                    couponCode == data.fld_code
                                                        ? removeCoupon(cartID, data.fld_offerid, "")
                                                        : applyOfferCoupon(data)
                                                }
                                            }}>
                                            {couponCode == data.fld_code ? (
                                                <div className="d-flex justify-content-between align-items-center applied-coupon-btn pb-1 pt-1">
                                                    <span>
                                                        Coupon Applied{" "}
                                                        <IoCheckmarkDone className="font-small" />
                                                    </span>
                                                    <span className="cursor-pointer">Remove</span>
                                                </div>
                                            ) : (
                                                "Tap to Apply"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={showCanvas == true ? true : false}
                // show={couponCard}
                centered
                // fullscreen="xl"
                // size="xl"
                scrollable="true"
                className="coupon-modal"
                onHide={handleCloseCanvas}>
                <Modal.Header
                    closeButton
                    className="pb-0 border-0">
                    <h4 className="section-title-category-section mt-0">
                        Add New Address
                    </h4>
                </Modal.Header>
                <Modal.Body className="py-0">
                    <AddressBookForm toggleCanvas={handleCloseCanvas} />
                </Modal.Body>
            </Modal>


            <Drawer title={<>Claim your {cartData[0]?.fld_offer_name} deal<br /></>} onClose={onClose} open={open} placement={displaysize > 568 ? "right" : "bottom"} className="drawer-down">
                <div className="row">
                    {offerItems?.map((items, index) => (
                        <div className="col-6 mb-3">
                            <div className="product-card bag-product-card rounded overflow-hidden border">
                                <div className="productimgbg h-50">
                                    {items.fld_imageurl.endsWith(".mp4") || items.fld_imageurl.endsWith(".gif") ?
                                        <video
                                            src={items.fld_imageurl}
                                            className="w-100 h-100 object-fit-cover"
                                            loop
                                            autoPlay
                                        >
                                        </video>
                                        :

                                        <img src={items.fld_imageurl} className="img-fluid w-100 h-100 object-fit-cover"
                                            alt={items.fld_variantname}
                                        />}


                                </div>
                                <div className="content h-50 pt-0">
                                    <div>
                                        <h6 className="mb-1 mt-1">
                                            {items.fld_variantname}
                                        </h6>
                                        <div className="price mb-1 d-flex gap-3 mb-2">
                                            FREE
                                            <del className="fs-6"><MdCurrencyRupee />{items.fld_website_discount_price}</del>
                                        </div>
                                    </div>
                                    <div className="mt-1">
                                        <AddToCart
                                            itemType="FreeItem"
                                            closeOfferCanvas={onClose}
                                            itemid={items.fld_variant_id}
                                            classNames={`btn default-btn-one default-btn-bags cursor-pointer mx-0 fs-6`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </Drawer >
        </>
    )

}
import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import "./App.css";
import AboutPage from "./Components/AboutPage/AboutPage";
import ContactPage from "./Components/ContactPage/ContactPage";
import CustomerProfilePage from "./Components/CustomerProfilePage/CustomerProfilePage";
import ViewAllOrders from "./Components/CustomerProfilePage/ViewAllOrders";
import FaqPage from "./Components/FaqPage/FaqPage";
import FixedWhatsappBtn from "./Components/FixedWhatsappBtn/FixedWhatsappBtn";
import Footer from "./Components/Footer/Footer";
import GoToTop from "./Components/GoToTop/GoToTop";
import ViewInvoiceForm from "./Components/Invoice/ViewInvoiceForm";
import InvoicePage from "./Components/InvoicePage/InvoicePage";
import FixedNavbar from "./Components/MainNavabar/MainNavabar";
import MobileNavbar from "./Components/MainNavabar/MobileNavbar";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import PaymentSuccess from "./Components/Payments/PaymentSuccess";
import ProductDetail from "./Components/ProductDetail/ProductDetail";
import ProductListing from "./Components/ProductListing/ProductListing";
import ProtectedRoutes from "./Components/RouteAuth/ProtectedRoutes";
import StaticPage from "./Components/StaticPage/StaticPage";
import Provider, { store } from "./Helper/Context/Store";
import GetApiCall from "./Helper/GetApi";
import finalcart from "./Pages/Cart/FinalCart";
import Home from "./Pages/Home";
import OfferSection from "./Components/OfferSection/OfferSection";

function App() {
  const [staticPages, setStaticPages] = useState([]);
  const { showOfferSection } = useContext(store)
  useEffect(() => {
    GetApiCall.getRequest("GetStaticPagesList").then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setStaticPages(obj1.data);
        }
      })
    );
  }, []);
  return (
    <>
      {/* <Provider> */}
      <Router>
        <Switch>
          <Route>
            <>
              <FixedNavbar />
              <MobileNavbar />
              <Switch>
                {staticPages?.map((dat, index) => {
                  return (
                    <Route
                      path={[dat.fld_route]}
                      key={index}
                      component={StaticPage}></Route>
                  );
                })}
                <Route exact path={"/"} component={Home} />
                <Route exact path={"/contact-us"} component={ContactPage} />
                <Route exact path={"/our-story"} component={AboutPage} />
                <Route path="/productdetail/flower-power/1" exact>
                  <Redirect
                    status={301}
                    to="/p/perfume-flower-power"></Redirect>
                </Route>
                <Route path="/productdetail/aqua-blue/6" exact>
                  <Redirect status={301} to="/p/perfume-aqua-blue"></Redirect>
                </Route>
                <Route path="/ct/bags/1/3" exact>
                  <Redirect status={301} to="/ct/bags"></Redirect>
                </Route>
                <Route path="/productdetail/orange-blossom/7" exact>
                  <Redirect
                    status={301}
                    to="/p/perfume-orange-blossom"></Redirect>
                </Route>
                <Route path="/productdetail/timber-gold/7" exact>
                  <Redirect
                    status={301}
                    to="/p/perfume-timber-gold"></Redirect>
                </Route>
                {/* <Route
                    exact
                    path={"/ct/:categoryname/:verticalid/:categoryid"}
                    component={ProductListing}
                  />
                  <Route
                    exact
                    path={"/productdetail/:productname/:variantid"}
                    component={ProductDetail}
                  /> */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/ct/:categoryname`}
                  component={ProductListing}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/pct/:productcategoryname`}
                  component={ProductListing}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/p/:productname`}
                  component={ProductDetail}
                />
                <Route exact path={"/cart"} component={finalcart} />
                {/* <Route
                    exact
                    path={"/newcart"}
                    component={NewCart}
                  /> */}
                <ProtectedRoutes
                  exact
                  path={"/customer-profile"}
                  component={CustomerProfilePage}
                // isAuth={
                //   localStorage.getItem("CustomerData") == null
                //     ? false
                //     : true
                // }
                />
                <ProtectedRoutes
                  exact
                  path={"/view-all-orders"}
                  component={ViewAllOrders}
                // isAuth={
                //   localStorage.getItem("CustomerData") == null
                //     ? false
                //     : true
                // }
                />
                <ProtectedRoutes
                  exact
                  path={"/paymentsuccess/:txnid"}
                  component={PaymentSuccess}
                // isAuth={
                //   localStorage.getItem("CustomerData") == null
                //     ? false
                //     : true
                // }
                />
                <Route
                  exact
                  path={"/view-invoice-form/:id/:orderid"}
                  component={ViewInvoiceForm}
                />
                <Route exact path={"/faq"} component={FaqPage} />

                <Route
                  exact
                  path={"/invoice/:id/:orderid"}
                  component={InvoicePage}
                />

                <Route exact path={"/*"} component={PageNotFound} />
              </Switch>

              <Footer />
              <FixedWhatsappBtn />
              <GoToTop />

              {showOfferSection === true && <OfferSection />}
            </>
          </Route>
        </Switch>
      </Router>
      {/* </Provider> */}
    </>
  );
}

export default App;

import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { store } from "../../Helper/Context/Store";
import AddToCart from "../AddToCart/AddToCart";
import AddToCartQty from "../AddToCart/AddToCartQty";
import "./ProductCard.css";
import { MdCurrencyRupee } from "react-icons/md";

const Products = [
  {
    addbtnstyle: "flower-add-btn",
    productname: "Flower Power",
  },
  {
    addbtnstyle: "orange-add-btn",
    productname: "Orange Blossom",
  },
  {
    addbtnstyle: "aqua-add-btn",
    productname: "Aqua Blue",
  },
  {
    addbtnstyle: "timber-add-btn",
    productname: "Timber Gold",
  },
];
export default function ProductCard(props) {
  const [variantImage, setVariantImage] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [variantName, setVariantName] = useState(null);
  const [itemName, setItemName] = useState(null);
  const [MRP, setMRP] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const { storeStatus } = useContext(store);
  const [productsbtncolor, setProductsBtnColor] = useState("")
  const [itemRoute, setItemRoute] = useState(null)
  const [inStock, setInStock] = useState("");

  useEffect(() => {
    try {
      setItemName(props.productData.fld_variantname);
      setItemRoute(props.productData?.fld_route);
      setMRP(
        props.productData.variant_list.split("#")[0].split("$")[1].split(",")[2]
      );
      setDiscountPrice(
        props.productData.variant_list.split("#")[0].split("$")[1].split(",")[3]
      );
      setVariantId(
        props.productData.variant_list.split("#")[0].split("$")[1].split(",")[1]
      );
      setVariantName(
        props.productData.variant_list.split("#")[0].split("$")[1].split(",")[0]
      );
      setCategoryName(props.productData.fld_categoryname);
      setInStock(props.productData.variant_list.split("#")[0].split("$")[1].split(",")[6]);
      setVariantImage(
        props.productData.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[7]
          .split("~")[0]
      );
    } catch {
      try {
        setItemName(props.productData.fld_variantname);
        setMRP(props.productData.variant_list.split("$")[1].split(",")[2]);
        setDiscountPrice(
          props.productData.variant_list.split("$")[1].split(",")[3]
        );
        setVariantId(
          props.productData.variant_list.split("$")[1].split(",")[1]
        );
        setVariantName(
          props.productData.variant_list.split("$")[1].split(",")[0]
        );
        setCategoryName(props.productData.fld_categoryname);
        setVariantImage(
          props.productData.variant_list
            .split("$")[1]
            .split(",")[7]
            .split("~")[0]
        );
      } catch { }
    }
  }, []);
  useEffect(() => {
    const findStyleNameFromURL = () => {
      const url = props.productData.fld_variantname.replace("-", " ").toLowerCase()
      let matchedStyle = '';
      Products?.map((data) => {
        if (url.includes(data.productname.toLowerCase())) {
          matchedStyle = data.addbtnstyle;
          return;
        }
      })
      setProductsBtnColor(matchedStyle);
    };

    findStyleNameFromURL();
  }, [props.productData.fld_variantname]);
  return (
    <div className="product-card bag-product-card
    ">
      <div className="productimgbg">
        <Link
          to={`${process.env.PUBLIC_URL
            }/p/${itemRoute?.replace(
              /\s/g,
              "-"
            ).replace('/', '')}`}
        >
          <img src={variantImage} className="img-fluid" alt={variantName} />
        </Link>

      </div>
      <div className="content">
        <div>
          <p className="cat-name">{categoryName}</p>

          <h6>
            <Link
              to={`${process.env.PUBLIC_URL
                }/p/${itemRoute?.replace(
                  /\s/g,
                  "-"
                ).replace('/', '')}`}
              className="p-0"
            >
              {variantName}
            </Link>
          </h6>
        </div>
        <div className="d-flex justify-content-between align-items-end flex-wrap flex-lg-nowrap mt-1">

          <div className="price mb-1">
            <MdCurrencyRupee /> {parseInt(discountPrice)}{" "}{
              parseInt(discountPrice) !== parseInt(MRP) &&
              <del><MdCurrencyRupee /> {parseInt(MRP)}</del>}
          </div>
          {/* {storeStatus == "Active" && ( */}
          {/* {inStock === "In Stock" ? */}
          <div className="d-flex mt-2 mt-lg-0">
            {props.isInCart ? (
              <AddToCartQty
                itemid={variantId}
                quantity={props.isInCart.fld_quantity}
                cartlineid={props.isInCart.fld_cart_dtl_id}
              />
            ) : (
              <span className=" cursor-pointer">
                <AddToCart itemid={variantId} classNames={`btn ${productsbtncolor} default-btn-bags default-btn-one cursor-pointer mx-0`} />
              </span>
            )}
          </div>
          {/* : <div className={`btn ${productsbtncolor} default-btn-bags default-btn-one cursor-pointer mx-0 sold-out`}>Sold Out</div>} */}
        </div>
        {/* <div className="row mt-3 px-2">
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-center border">
            {storeStatus == "Active" && (
              <div className="d-flex justify-content-lg-end align-items-end">
                {props.isInCart ? (
                  <AddToCartQty
                    itemid={variantId}
                    quantity={props.isInCart.fld_quantity}
                    cartlineid={props.isInCart.fld_cart_dtl_id}
                  />
                ) : (
                  <button className="border-0 py-1 px-2 text-dark bg-transparent">
                    <AddToCart itemid={variantId} />
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="col-12 col-md-6 mt-2 mb-3 my-md-0 d-flex align-items-center justify-content-center border">
            &#x20B9; {parseInt(discountPrice)}{" "}
            <del className="ms-2">&#x20B9; {parseInt(MRP)}</del>
          </div>
        </div> */}
      </div>
    </div>
  );
}
